import React from 'react';

import { Button, Typography } from 'antd';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { LOGIN_ROUTE, REGISTER_ROUTE } from 'routes/user-management/list';

import LandingImage from '../landing-image/LandingImage';
import s from './Landing.module.scss';

const { Title } = Typography;

const Landing = () => {
  return (
    <div className={s.landingWrapper}>
      <LandingImage />
      <div className={s.content}>
        <img src="/assets/images/logo.svg" alt="featureImg" width={280} />
        <Title className={s.landingTitle}>Find your place</Title>
        <p style={{ fontSize: 24, marginTop: 16, marginBottom: 16 }}>
          Location and price of properties on our interactive map of Malta.
        </p>
        <ul style={{ marginLeft: 24 }}>
          <li style={{ fontSize: 20, marginBottom: 8 }}>List for free</li>
          <li style={{ fontSize: 20, marginBottom: 8 }}>
            For property owners, seekers and agents.
          </li>
          <li style={{ fontSize: 20, marginBottom: 8 }}>
            First users will get discounted premium accounts.
          </li>
        </ul>
        <div className={s.buttonGroup}>
          <RouterNavLink style={{ width: '100%' }} to={REGISTER_ROUTE}>
            <Button className={s.firstButton}>SIGN UP</Button>
          </RouterNavLink>
          <RouterNavLink style={{ width: '100%' }} to={LOGIN_ROUTE}>
            <Button className={s.secondButton}>KNOCK KNOCK</Button>
          </RouterNavLink>
        </div>
      </div>
    </div>
  );
};

export default Landing;
