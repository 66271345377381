import React, { FC, useCallback } from 'react';

import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getEditPropertyRoute, getPropertyDetailRoute } from 'routes/property/list';
import { Icon, Label, Typography, message } from 'ui';

import { useDeletePropertyMutation } from 'services/properties/propertyApiService';
import { removePropertyById } from 'slices/propertySlice';
import { useAppDispatch } from 'store/index';

import { GetPropertyType } from 'types/property/propertyTypes';

// Import Mapbox CSS
import s from './PropertyCard.module.scss';

interface PropertyCardProps {
  property: GetPropertyType;
  editable?: boolean;
}

const formatCurrency = (value) => {
  return `€${value.toLocaleString()}`;
};

const PropertyCard: FC<PropertyCardProps> = ({ property, editable }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [deleteProperty, { isLoading: removing }] = useDeletePropertyMutation();

  const handleDeleteProperty = useCallback(
    async (id: string) => {
      const res = await deleteProperty(id);
      if ('data' in res) {
        dispatch(removePropertyById(id));
        message.warning('Property removed!');
      }
    },
    [deleteProperty, dispatch],
  );

  return (
    <div className={s.wrapper}>
      <div className={s.propertyLink}>
        <div style={{ position: 'relative', textAlign: 'center' }}>
          {property?.imageURL ? (
            <img src={property?.imageURL} alt="property" className={s.propertyImage} />
          ) : (
            <img src="/assets/images/no-image.png" alt="property" className={s.propertyImage} />
          )}
          {editable && (
            <>
              <Button
                className={s.editButton}
                onClick={() => navigate(`${getEditPropertyRoute(property?.id)}`)}>
                <Icon name="edit" />
              </Button>
              <Button
                className={s.deleteButton}
                disabled={removing}
                type="link"
                onClick={() => handleDeleteProperty(property?.id)}>
                <Icon name="trashBox" size={14} />
              </Button>
            </>
          )}
        </div>
        <Button onClick={() => navigate(`${getPropertyDetailRoute(property?.id)}`)}>Preview</Button>
        <Typography type="h4">{formatCurrency(property?.price)}</Typography>
        {property.featuredParameters && property.featuredParameters.length > 0 && (
          <Typography type="h6">
            {property.featuredParameters
              .map((param) => `${param.value} ${param.shortenedCaption}`)
              .join(' • ')}{' '}
            • {property.totalSize}м²
          </Typography>
        )}
        <Label className={s.label}>{property?.address?.street}</Label>
      </div>
    </div>
  );
};

export default PropertyCard;
