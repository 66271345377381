import { UserProfileType } from 'types/core/userTypes';

export enum PAYMENT_TYPE_ENUM {
  cash = 'Cash',
  paypal = 'PayPal',
  creditCard = 'Credit Card',
  complimentary = 'Complimentary',
  cheque = 'Cheque',
}
export enum ORDER_STATUSES_ENUM {
  cancelled = 'Cancelled',
  completed = 'Completed',
  initiated = 'Initiated',
  userCancelled = 'User Cancelled',
  payPalTimeout = 'PayPal Timeout',
  creditCardPending = 'Credit Card Pending',
  payPalError = 'PayPal Error',
  paymentFinalized = 'Payment Finalized',
  refunded = 'Refunded',
  deleted = 'Deleted',
  paymentDeclined = 'Payment Declined',
  expired = 'Expired',
  booked = 'Booked',
}

export enum TICKET_STATUS_ENUM {
  open = 'Open',
  closed = 'Closed',
  disabled = 'Disabled',
  invalid = 'Invalid',
  refunded = 'Refunded',
  deleted = 'Deleted',
  possibleFraud = 'Possible Fraud',
  depositPaid = 'Deposit Paid',
  inUse = 'In Use',
}

export enum ORDER_PENDING_STATUSES {
  INITIATED = 'Initiated',
  CREDIT_CARD_PENDING = 'CreditCardPending',
  PAY_PAL_TIMEOUT = 'PayPalTimeout',
}

export enum ORDER_SUCCESS_STATUSES {
  COMPLETED = 'Completed',
  PAYMENT_FINALIZED = 'PaymentFinalized',
  BOOKED = 'Booked',
}

export enum ORDER_FAILED_STATUSES {
  PAYMENT_DECLINED = 'PaymentDeclined',
  PAY_PAL_ERROR = 'PayPalError',
  CANCELLED = 'Cancelled',
  USER_CANCELLED = 'UserCancelled',
  REFUNDED = 'Refunded',
  EXPIRED = 'Expired',
  DELETED = 'Deleted',
}

export type TicketFullType = {
  ticketHolderPersonalInfo: {
    firstName: string;
    lastName: string;
  };
  isSeated: boolean;
  numberOfPersons: number;
  barcodeVisibilityType: string;
  ticketId: string;
  value: number;
  ticketType: {
    name: string;
    id: string;
    description: string;
  };
  receiptNumber: string;
  subTotal: number;
  bookingFee: number;
  total: number;
  seat: {
    seatId: string;
    fullDisplayName: string;
    rowName: string;
    seatNumber: string;
    section: {
      sectionId: string;
      displayName: string;
    };
    location: {
      locationId: string;
      name: string;
    };
  };
  show: {
    showId: string;
    eventId: string;
    date: string;
    time: string;
  };
  ticketStatus: string;
  barCode: string;
  attendanceDate: string;
  longAttendanceDate: string;
  dateId: string;
  scheduleType: string;
  holder: {
    firstName: string;
    fullName: string;
    mobileNumber: string;
    lastName: string;
    userId: string;
    email: string;
  };
};

export type TicketType = {
  numberOfPersons: number;
  orderId: string;
  orderNo: string;
  orderDate: string;
  orderTotal: number;
  orderSubTotal: number;
  bookingFee: number;
  paymentType: string;
  orderStatus: ORDER_STATUSES_ENUM;
  user: UserProfileType;
};

export type OrderTableType = {
  numberOfPersons: number;
  orderId: string;
  orderNo: string;
  orderDate: string;
  orderTotal: number;
  orderSubTotal: number;
  bookingFee: number;
  paymentType: string;
  orderStatus: ORDER_STATUSES_ENUM;
  user: UserProfileType;
  flyer: string;
  eventName: string;
};

interface Section {
  id: string;
  name: string;
  svg: string;
  sectionType: string;
  ticketTpe: {
    name: string;
    ticketTypeId: string;
    description: string;
  };
}

export type SectionListTableType = {
  topPlanSVG: string;
  name: string;
  id: string;
  sections: Section[];
  shows: {
    date: string;
    id: string;
    disabledSections: {
      id: string;
      name: string;
      svg: string;
      sectionType: string;
      disableType: string;
    }[];
    controlledSVGElements: {
      className: string;
      visibilityType: string;
      appliesToAdmin: boolean;
    }[];
  }[];
};

export type OrdersListType = {
  orders: TicketType[];
  nextPage: number;
  previousPage: number;
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  totalRecords: number;
  totalRecordsInPage: number;
};

export type OrderType = TicketType & {
  refundProtection: string;
  remarks: string;
  referredBy: string;
  gatewayType: string;
  tickets: TicketFullType[];
};

export type OrderFullInfoType = {
  tickets: TicketFullType[];
  refundProtection: string;
  remarks: string | null;
  referredBy: string | null;
  gatewayType: string | null;
  orderId: string;
  orderNo: string;
  orderDate: string;
  orderTotal: number;
  orderSubTotal: number;
  bookingFee: number;
  paymentType: string;
  orderStatus: string;
  user: UserProfileType;
};
