import { FC, useCallback, useEffect, useRef, useState } from 'react';

// import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Progress, Radio, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import cn from 'classnames';
import mt from 'react-phone-input-2';
import PhoneInput from 'react-phone-input-2';
import { useParams } from 'react-router-dom';
import { Form, Icon, Input, Label, Typography, Upload, message } from 'ui';

// import { formRules } from 'utils/formRules';
import { createActivePropertySelector } from 'selectors/propertySlice.selector';
import {
  useCreatePropertyMutation,
  useDeletePropertyImageMutation,
  useLazyGetPropertyDetailQuery, // useDeletePropertyImageMutation,
  useLazyGetPropertyInfoQuery,
  useUpdatePropertyMutation,
  useUploadPropertyImageMutation,
} from 'services/properties/propertyApiService';
import { setActiveProperty } from 'slices/propertySlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import {
  CommercialTypeEnum,
  GroundRentTypeEnum,
  OrientationTypeEnum,
  PostingTypeEnum, // SaleStatusEnum,
} from 'types/property/propertyEnum';
import { PropertyType } from 'types/property/propertyTypes';

import s from './PostProperty.module.scss';
import {
  contactVisibilityTypeOption,
  createPropertyContext,
  groundRentTypeOption,
  orientationType,
  postTypeOption,
  propertyTypeOption, // statusOption,
} from './postProperty.utils';
import PropertyLocationVenue from './property-location-venue';
import { PreparePropertyLocationVenueResult } from './property-location-venue/propertyLocationVenue.utils';

const PostProperty: FC = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const selectedCommercialType = Form.useWatch('commercialType', form);
  const selectedType = Form.useWatch('type', form);
  const groundRentType = Form.useWatch('groundRentType', form);
  const internalSize = Form.useWatch(['parameters', '11'], form);
  const externalSize = Form.useWatch(['parameters', '12'], form);
  const [getPropertyData, { data: propertyInfo }] = useLazyGetPropertyInfoQuery();
  const [uploadPropertyImage] = useUploadPropertyImageMutation();
  const [createProperty, { isLoading: creating }] = useCreatePropertyMutation();
  const [updateProperty, { isLoading: updating }] = useUpdatePropertyMutation();
  const [getPropertyDetail] = useLazyGetPropertyDetailQuery();
  const { propertyId } = useParams();
  const activeProperty = useAppSelector(createActivePropertySelector);
  const [deletePropertyImage, { isLoading: removing }] = useDeletePropertyImageMutation();
  const [loading, setLoading] = useState<string>('');
  const [locationData, setLocationData] = useState<PreparePropertyLocationVenueResult>();
  const [percent, setPercent] = useState<number>(20);
  const [isDirty1, setIsDirty1] = useState<boolean>(false);
  const [isDirty2, setIsDirty2] = useState<boolean>(false);
  const [isDirty3, setIsDirty3] = useState<boolean>(false);
  // const [isDirty4, setIsDirty4] = useState<boolean>(false);
  // const [isDirty5, setIsDirty5] = useState<boolean>(false);
  // validation checking
  const [isRequiredName, setIsRequiredName] = useState<boolean>(false);
  const [isRequirePrice, setIsRequiredPrice] = useState<boolean>(false);
  const [isRequireRentPrice, setIsRequiredRentPrice] = useState<boolean>(false);
  const [isRequiredStreet, setIsRequiredStreet] = useState<boolean>(false);
  const [isRequiredTown, setIsRequiredTown] = useState<boolean>(false);
  const [isRequiredPostcode, setIsRequiredPostcode] = useState<boolean>(false);

  const contentRef1 = useRef<HTMLDivElement>(null);
  const contentRef2 = useRef<HTMLDivElement>(null);
  const contentRef3 = useRef<HTMLDivElement>(null);
  const contentRef4 = useRef<HTMLDivElement>(null);
  const contentRef5 = useRef<HTMLDivElement>(null);

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // Align at the top of the container
      });
    }
  };
  const handleChange = () => {
    const nameValue = form.getFieldValue('name');
    if (nameValue) {
      setIsRequiredName(false);
    } else {
      setIsRequiredName(true);
    }

    const priceValue = form.getFieldValue('price');
    if (priceValue) {
      setIsRequiredPrice(false);
    } else {
      setIsRequiredPrice(true);
    }

    const rentPriceValue = form.getFieldValue('groundRentPrice');
    if (rentPriceValue) {
      setIsRequiredRentPrice(false);
    } else {
      setIsRequiredRentPrice(true);
    }

    const streetValue = form.getFieldValue(['address', 'street']);
    if (streetValue) {
      setIsRequiredStreet(false);
    } else {
      setIsRequiredStreet(true);
    }

    const townValue = form.getFieldValue(['address', 'town']);
    if (townValue) {
      setIsRequiredTown(false);
    } else {
      setIsRequiredTown(true);
    }

    const postcodeValue = form.getFieldValue(['address', 'postcode']);
    if (postcodeValue) {
      setIsRequiredPostcode(false);
    } else {
      setIsRequiredPostcode(true);
    }

    if (
      form.isFieldTouched('name') ||
      form.isFieldTouched('commercialType') ||
      form.isFieldTouched('postingType') ||
      form.isFieldTouched('type') ||
      form.isFieldTouched('subType') ||
      form.isFieldTouched('price') ||
      form.isFieldTouched('groundRentType') ||
      form.isFieldTouched('groundRentPrice')
    ) {
      setIsDirty1(true);
    }

    if (
      form.isFieldTouched(['address', 'country']) ||
      form.isFieldTouched(['address', 'street']) ||
      form.isFieldTouched(['address', 'city']) ||
      form.isFieldTouched(['address', 'postcode']) ||
      form.isFieldTouched('orientationType')
    ) {
      setIsDirty2(true);
    }

    if (
      form.isFieldTouched('finishStatus') ||
      form.isFieldTouched(['parameters', 1]) ||
      form.isFieldTouched(['parameters', 2]) ||
      form.isFieldTouched(['parameters', 3]) ||
      form.isFieldTouched(['parameters', 4]) ||
      form.isFieldTouched(['parameters', 5]) ||
      form.isFieldTouched(['parameters', 6]) ||
      form.isFieldTouched(['parameters', 7]) ||
      form.isFieldTouched(['parameters', 8]) ||
      form.isFieldTouched(['parameters', 9]) ||
      form.isFieldTouched(['parameters', 10]) ||
      form.isFieldTouched(['parameters', 11]) ||
      form.isFieldTouched('specialFeatures') ||
      form.isFieldTouched('comments')
    ) {
      setIsDirty3(true);
    }
  };

  const customRequest = async (options) => {
    const { file, onError } = options;

    try {
      const toBase64 = (file: File) =>
        new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = (error) => reject(error);
        });

      const fileBase64 = await toBase64(file);

      // Create a payload with the base64 string and other fields

      const payload = {
        id: activeProperty?.id,
        base64Image: fileBase64,
        fileName: file.name,
        description: file.name,
        isMain: true,
      };
      const res = await uploadPropertyImage(payload);

      if ('data' in res) {
        message.success('Image successfully uploaded!');
        setLoading('done');
        dispatch(setActiveProperty(res.data as PropertyType));
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleSubmit = async (step: string) => {
    try {
      const values = await form.getFieldsValue();

      if (step === 'basicInfo') {
        if (!form.getFieldValue('name')) {
          setIsRequiredName(true);
          return;
        }
        if (!form.getFieldValue('price')) {
          setIsRequiredPrice(true);
          return;
        }
        if (
          groundRentType === GroundRentTypeEnum.GroundRent &&
          !form.getFieldValue('groundRentPrice')
        ) {
          setIsRequiredRentPrice(true);
          return;
        }
        if (values?.name && values?.price) {
          const result = activeProperty
            ? {
                id: activeProperty?.id,
                ...values,
                parameters: values.parameters
                  .map((value, index) => ({
                    id: index,
                    value: value || null, // Replace `null` with "string"
                  }))
                  .filter((param) => param.value !== null),
                specialFeatures: values?.specialFeatures
                  ? values?.specialFeatures?.map((value) => ({
                      id: value,
                    }))
                  : null,
                coordinates: {
                  longitude: locationData?.latLng.lng,
                  latitude: locationData?.latLng.lat,
                },
              }
            : {
                ...values,
                parameters: values.parameters
                  .map((value, index) => ({
                    id: index,
                    value: value || null, // Replace `null` with "string"
                  }))
                  .filter((param) => param.value !== null),
                specialFeatures: values?.specialFeatures
                  ? values?.specialFeatures?.map((value) => ({
                      id: value,
                    }))
                  : null,
                coordinates: {
                  longitude: locationData?.latLng.lng,
                  latitude: locationData?.latLng.lat,
                },
              };
          const res = activeProperty?.id
            ? await updateProperty(result)
            : await createProperty(result);
          if ('data' in res) {
            activeProperty?.id
              ? message.success('Property successfully updated!')
              : message.success('Property successfully created!');
            scrollToRef(contentRef2);
            setPercent(40);
            dispatch(setActiveProperty(res.data as PropertyType));
          }
        }
      }
      if (step === 'address' && activeProperty?.id) {
        if (!form.getFieldValue(['address', 'postcode'])) {
          setIsRequiredPostcode(true);
        }
        if (!form.getFieldValue(['address', 'street'])) {
          setIsRequiredStreet(true);
        }
        if (!form.getFieldValue(['address', 'town'])) {
          setIsRequiredTown(true);
        }

        if (values?.address?.postcode && values?.address?.street && values?.address?.town) {
          const result = activeProperty
            ? {
                id: activeProperty?.id,
                ...values,
                parameters: values.parameters
                  .map((value, index) => ({
                    id: index,
                    value: value || null, // Replace `null` with "string"
                  }))
                  .filter((param) => param.value !== null),
                specialFeatures: values?.specialFeatures
                  ? values?.specialFeatures?.map((value) => ({
                      id: value,
                    }))
                  : null,
                coordinates: {
                  longitude: locationData?.latLng.lng,
                  latitude: locationData?.latLng.lat,
                },
              }
            : {
                ...values,
                parameters: values.parameters
                  .map((value, index) => ({
                    id: index,
                    value: value || null, // Replace `null` with "string"
                  }))
                  .filter((param) => param.value !== null),
                specialFeatures: values?.specialFeatures
                  ? values?.specialFeatures?.map((value) => ({
                      id: value,
                    }))
                  : null,
                coordinates: {
                  longitude: locationData?.latLng.lng,
                  latitude: locationData?.latLng.lat,
                },
              };
          const res = activeProperty?.id
            ? await updateProperty(result)
            : await createProperty(result);
          if ('data' in res) {
            activeProperty?.id
              ? message.success('Property successfully updated!')
              : message.success('Property successfully created!');
            scrollToRef(contentRef3);
            setPercent(60);
            dispatch(setActiveProperty(res.data as PropertyType));
          }
        }
      }
      if (step === 'details' && activeProperty?.id) {
        const result = activeProperty
          ? {
              id: activeProperty?.id,
              ...values,
              parameters: values.parameters
                .map((value, index) => ({
                  id: index,
                  value: value || null, // Replace `null` with "string"
                }))
                .filter((param) => param.value !== null),
              specialFeatures: values?.specialFeatures
                ? values?.specialFeatures?.map((value) => ({
                    id: value,
                  }))
                : null,
              coordinates: {
                longitude: locationData?.latLng.lng,
                latitude: locationData?.latLng.lat,
              },
            }
          : {
              ...values,
              parameters: values.parameters
                .map((value, index) => ({
                  id: index,
                  value: value || null, // Replace `null` with "string"
                }))
                .filter((param) => param.value !== null),
              specialFeatures: values?.specialFeatures
                ? values?.specialFeatures?.map((value) => ({
                    id: value,
                  }))
                : null,
              coordinates: {
                longitude: locationData?.latLng.lng,
                latitude: locationData?.latLng.lat,
              },
            };
        const res = activeProperty?.id
          ? await updateProperty(result)
          : await createProperty(result);
        if ('data' in res) {
          activeProperty?.id
            ? message.success('Property successfully updated!')
            : message.success('Property successfully created!');
          scrollToRef(contentRef4);
          setPercent(80);
          dispatch(setActiveProperty(res.data as PropertyType));
        }
      }
      if (step === 'last' && activeProperty?.id) {
        const result = activeProperty
          ? {
              id: activeProperty?.id,
              ...values,
              parameters: values.parameters
                .map((value, index) => ({
                  id: index,
                  value: value || null, // Replace `null` with "string"
                }))
                .filter((param) => param.value !== null),
              specialFeatures: values?.specialFeatures
                ? values?.specialFeatures?.map((value) => ({
                    id: value,
                  }))
                : null,
              coordinates: {
                longitude: locationData?.latLng.lng,
                latitude: locationData?.latLng.lat,
              },
            }
          : {
              ...values,
              parameters: null,
              specialFeatures: null,
              coordinates: null,
            };
        const res = activeProperty?.id
          ? await updateProperty(result)
          : await createProperty(result);
        if ('data' in res) {
          activeProperty?.id
            ? message.success('Property successfully updated!')
            : message.success('Property successfully created!');
          scrollToRef(contentRef5);
          setPercent(100);
          dispatch(setActiveProperty(res.data as PropertyType));
        }
      }
    } catch (errorInfo) {
      console.log('Validation Failed:', errorInfo);
    }
  };

  const handleDeleteMedia = useCallback(
    async (id: string) => {
      const res = await deletePropertyImage(id);
      if ('data' in res) {
        message.warning('Image removed!');
        dispatch(setActiveProperty(res.data as PropertyType));
      }
    },
    [dispatch, deletePropertyImage],
  );

  const handleGetProperty = useCallback(
    async (propertyId: string) => {
      const res = await getPropertyDetail(propertyId);
      if ('data' in res) {
        dispatch(setActiveProperty(res.data as PropertyType));
      }
    },
    [dispatch, getPropertyDetail],
  );

  useEffect(() => {
    if (propertyId) {
      handleGetProperty(propertyId);
    }
  }, [propertyId, handleGetProperty]);

  useEffect(() => {
    if (!activeProperty) {
      form.resetFields();
    }
  }, [activeProperty, form]);

  useEffect(() => {
    if (activeProperty?.saleStatus) {
      form.setFieldValue('saleStatus', activeProperty.saleStatus);
    }
    if (activeProperty?.name) {
      form.setFieldValue('name', activeProperty.name);
    }
    if (activeProperty?.commercialType) {
      form.setFieldValue('commercialType', activeProperty.commercialType);
    }
    if (activeProperty?.postingType) {
      form.setFieldValue('postingType', activeProperty.postingType);
    }
    if (activeProperty?.type) {
      form.setFieldValue('type', activeProperty.type);
    }
    if (activeProperty?.price) {
      form.setFieldValue('price', activeProperty.price);
    }
    if (activeProperty?.groundRentType) {
      form.setFieldValue('groundRentType', activeProperty.groundRentType);
    }
    if (activeProperty?.groundRentPrice) {
      form.setFieldValue('groundRentPrice', activeProperty.groundRentPrice);
    }
    if (activeProperty?.orientationType) {
      form.setFieldValue('orientationType', activeProperty.orientationType);
    }
    if (activeProperty?.finishStatus) {
      form.setFieldValue('finishStatus', activeProperty.finishStatus);
    }
    if (activeProperty?.parameters?.length! > 0) {
      activeProperty?.parameters!.map((item) => {
        form.setFieldValue(['parameters', item.id], item.value);
      });
    }
    if (activeProperty?.specialFeatures) {
      const specialFeatures = activeProperty.specialFeatures.map((item) => item.id);
      form.setFieldValue('specialFeatures', specialFeatures);
    }
    if (activeProperty?.comments) {
      form.setFieldValue('comments', activeProperty.comments);
    }
    if (activeProperty?.owner) {
      form.setFieldValue('phone', activeProperty?.owner?.mobileNumber);
    }

    if (activeProperty?.address) {
      form.setFieldValue(['address', 'street'], activeProperty?.address?.street);
      form.setFieldValue(['address', 'postcode'], activeProperty?.address?.postCode);
      form.setFieldValue(['address', 'town'], activeProperty?.address?.town);
      const tempLocation = {
        latLng: {
          lat: activeProperty?.coordinates?.latitude,
          lng: activeProperty?.coordinates?.longitude,
        },
        town: activeProperty?.address?.town,
        street: activeProperty?.address?.street,
        postcode: activeProperty?.address?.postCode,
      };
      setLocationData(tempLocation);
    }
  }, [activeProperty, form]);

  useEffect(() => {
    if (internalSize && externalSize) {
      form.setFieldValue('totalSize', parseInt(internalSize, 10) + parseInt(externalSize, 10));
    }
  }, [internalSize, externalSize, form]);

  useEffect(() => {
    if (selectedCommercialType === 'residential') {
      if (!activeProperty) {
        form.setFieldValue('type', 'Apartment');
      } else {
        if (activeProperty?.commercialType !== 'residential') {
          form.setFieldValue('type', 'Apartment');
        } else {
          form.setFieldValue('type', activeProperty?.type);
        }
      }
      getPropertyData(selectedCommercialType);
    } else if (selectedCommercialType === 'commercial') {
      if (!activeProperty) {
        form.setFieldValue('type', 'Office');
      } else {
        if (activeProperty?.commercialType !== 'commercial') {
          form.setFieldValue('type', 'Office');
        } else {
          form.setFieldValue('type', activeProperty?.type);
        }
      }
      getPropertyData(selectedCommercialType);
    }
  }, [selectedCommercialType, getPropertyData, form, activeProperty]);

  useEffect(() => {
    if (selectedType === 'Apartment') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Apartment');
      } else {
        if (activeProperty?.type === 'Apartment') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Apartment');
        }
      }
    } else if (selectedType === 'House') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Terraced house');
      } else {
        if (activeProperty?.type === 'House') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Terraced house');
        }
      }
    } else if (selectedType === 'Garage') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Lockup Garage');
      } else {
        if (activeProperty?.type === 'Garage') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Lockup Garage');
        }
      }
    } else if (selectedType === 'Plot') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Site for development');
      } else {
        if (activeProperty?.type === 'Plot') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Site for development');
        }
      }
    } else if (selectedType === 'Office') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Office');
      } else {
        if (activeProperty?.type === 'Office') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Office');
        }
      }
    } else if (selectedType === 'Retail') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Shop');
      } else {
        if (activeProperty?.type === 'Retail') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Shop');
        }
      }
    } else if (selectedType === 'Catering') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Restaurant');
      } else {
        if (activeProperty?.type === 'Catering') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Restaurant');
        }
      }
    } else if (selectedType === 'Hospitality') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Hotel');
      } else {
        if (activeProperty?.type === 'Hospitality') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Hotel');
        }
      }
    } else if (selectedType === 'WareHouse') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'WareHouse');
      } else {
        if (activeProperty?.type === 'WareHouse') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'WareHouse');
        }
      }
    } else if (selectedType === 'Industrial') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Factory');
      } else {
        if (activeProperty?.type === 'Industrial') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Factory');
        }
      }
    }
  }, [selectedType, form, activeProperty]);

  const subTypeContent = {
    Apartment: (
      <Space direction="vertical">
        <Radio value="Apartment">Apartment</Radio>
        <Radio value="Maisonette">Maisonette</Radio>
        <Radio value="Ground Floor Maisonette">Ground Floor Maisonette</Radio>
        <Radio value="Penthouse">Penthouse</Radio>
        <Radio value="Studio">Studio</Radio>
      </Space>
    ),
    House: (
      <Space direction="vertical">
        <Radio value="Terraced house">Terraced house</Radio>
        <Radio value="Townhouse">Townhouse</Radio>
        <Radio value="Semi detached villa">Semi detached villa</Radio>
        <Radio value="Detached villa">Detached villa</Radio>
        <Radio value="Farmhouse">Farmhouse</Radio>
        <Radio value="House of Character">House of Character</Radio>
        <Radio value="Bungalow">Bungalow</Radio>
        <Radio value="Palazzo, Manor">Palazzo, Manor</Radio>
      </Space>
    ),
    Garage: (
      <Space direction="vertical">
        <Radio value="Lockup Garage">Lockup Garage</Radio>
        <Radio value="Car Space">Car Space</Radio>
      </Space>
    ),
    Plot: (
      <Space direction="vertical">
        <Radio value="Site for development">Site for development</Radio>
        <Radio value="Plot for development">Plot for development</Radio>
        <Radio value="ODZ Land">ODZ Land</Radio>
      </Space>
    ),
  };

  const commercialTypeContent = {
    Office: (
      <Space direction="vertical">
        <Radio value="Office">Office</Radio>
      </Space>
    ),
    Retail: (
      <Space direction="vertical">
        <Radio value="Shop">Shop</Radio>
        <Radio value="Showroom">Showroom</Radio>
        <Radio value="Clinic">Clinic</Radio>
      </Space>
    ),
    Catering: (
      <Space direction="vertical">
        <Radio value="Restaurant">Restaurant</Radio>
        <Radio value="Take-Away">Take-Away</Radio>
        <Radio value="Bar">Bar</Radio>
        <Radio value="Club">Club</Radio>
      </Space>
    ),
    Hospitality: (
      <Space direction="vertical">
        <Radio value="Hotel">Hotel</Radio>
        <Radio value="GuestHouse">GuestHouse</Radio>
      </Space>
    ),
    WareHouse: (
      <Space direction="vertical">
        <Radio value="WareHouse">WareHouse</Radio>
      </Space>
    ),
    Industrial: (
      <Space direction="vertical">
        <Radio value="Factory">Factory</Radio>
        <Radio value="Workshop">Workshop</Radio>
      </Space>
    ),
  };

  return (
    <div className={s.wrapper}>
      <div className={s.leftMenu}>
        <div className={s.progressLabel}>
          <Typography type="h5">PROGRESS</Typography>
          <Typography type="h5">
            {percent / 20} <span style={{ color: '#7F86A4' }}>OF 5</span>
          </Typography>
        </div>
        <Progress percent={percent} type="line" />

        <ul style={{ listStyle: 'none', marginTop: 32 }}>
          <li className={cn(s.menuItem, { [s.active]: percent === 20 })}>
            <Button
              type="link"
              onClick={() => {
                setPercent(20);
                scrollToRef(contentRef1);
              }}>
              1. Type of posting
            </Button>
          </li>
          <li className={cn(s.menuItem, { [s.active]: percent === 40 })}>
            <Button
              type="link"
              onClick={() => {
                scrollToRef(contentRef2);
                setPercent(40);
              }}>
              2. Address
            </Button>
          </li>
          <li className={cn(s.menuItem, { [s.active]: percent === 60 })}>
            <Button
              type="link"
              onClick={() => {
                setPercent(60);
                scrollToRef(contentRef3);
              }}>
              3. Property details
            </Button>
          </li>
          <li className={cn(s.menuItem, { [s.active]: percent === 80 })}>
            <Button
              type="link"
              onClick={() => {
                setPercent(80);
                scrollToRef(contentRef4);
              }}>
              4. Property parameters
            </Button>
          </li>
          <li className={cn(s.menuItem, { [s.active]: percent === 100 })}>
            <Button
              type="link"
              onClick={() => {
                setPercent(100);
                scrollToRef(contentRef5);
              }}>
              5. Contact details
            </Button>
          </li>
        </ul>
      </div>
      <createPropertyContext.Provider value={{ form, onChangeForm: handleChange }}>
        <Form
          className={s.form}
          form={form}
          onValuesChange={handleChange}
          initialValues={{
            type: 'Apartment',
            subType: 'Apartment',
          }}>
          <Typography type="h2">Your property</Typography>
          <div className={s.formBlock}>
            <div className={s.anchor} ref={contentRef1}></div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <span className={s.subTitle}>1</span>
              <Typography type="h4">Type of property</Typography>
            </div>
            <div className={s.propertyItem}>
              <Typography type="h5">Property title</Typography>
              <div>
                <Form.Item name="name">
                  <Input
                    name="name"
                    className={isRequiredName && s.inputError}
                    style={{ backgroundColor: 'white', width: '50%' }}
                  />
                </Form.Item>
                {isRequiredName && (
                  <Typography className={s.errorMessage}>This field is required</Typography>
                )}
              </div>
            </div>
            <div className={s.propertyItem}>
              <Typography type="h5">Commercial type</Typography>
              <Form.Item name="commercialType" initialValue={CommercialTypeEnum.Residential}>
                <Radio.Group
                  className={s.filterSwitch}
                  options={propertyTypeOption}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </div>
            <div className={s.propertyItem}>
              <Typography type="h5">Type of posting</Typography>
              <Form.Item name="postingType" initialValue={PostingTypeEnum.Sale}>
                <Radio.Group
                  className={s.filterSwitch}
                  options={postTypeOption}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </div>
            {selectedCommercialType === CommercialTypeEnum.Residential ? (
              <div className={s.propertyItemStart}>
                <Typography type="h5">Property</Typography>
                <Form.Item name="type">
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio value="Apartment">Apartment, Maisonette or Penthouse</Radio>
                      <Radio value="House">House</Radio>
                      <Radio value="Garage">Garage</Radio>
                      <Radio value="Plot">Plot, Land or Field</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
            ) : (
              <div className={s.propertyItemStart}>
                <Typography type="h5">Commercial Property</Typography>
                <Form.Item name="type">
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio value="Office">Office</Radio>
                      <Radio value="Retail">Retail</Radio>
                      <Radio value="Catering">Catering</Radio>
                      <Radio value="Hospitality">Hospitality</Radio>
                      <Radio value="WareHouse">Warehouse</Radio>
                      <Radio value="Industrial">Industrial</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
            )}
            <Divider style={{ borderColor: '#CED2E5' }} />
            <div className={s.propertyItemStart}>
              <Typography type="h5">Type of property</Typography>
              <Form.Item name="subType">
                {selectedCommercialType === CommercialTypeEnum.Residential ? (
                  <Radio.Group>{subTypeContent[selectedType!]} </Radio.Group>
                ) : (
                  <Radio.Group>{commercialTypeContent[selectedType!]} </Radio.Group>
                )}
              </Form.Item>
            </div>
            <div className={s.propertyItem}>
              <Typography type="h5">Price €</Typography>
              <div>
                <Form.Item name="price">
                  <Input.Number
                    name="price"
                    size="large"
                    className={isRequirePrice && s.inputError}
                    formatter={(value) =>
                      value ? `€ ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : ''
                    }
                    parser={(value) => (value ? value.replace(/€\s?|,/g, '') : '')}
                    style={{ backgroundColor: 'white', width: '50%', borderRadius: 6 }}
                  />
                </Form.Item>
                {isRequirePrice && (
                  <Typography className={s.errorMessage}>This field is required</Typography>
                )}
              </div>
            </div>
            <div className={s.propertyItem}>
              <Typography type="h5">Ground Rent</Typography>
              <Form.Item name="groundRentType" initialValue={GroundRentTypeEnum.FreeHold}>
                <Radio.Group
                  className={s.filterSwitch}
                  options={groundRentTypeOption}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </div>
            {groundRentType === GroundRentTypeEnum.GroundRent && (
              <div className={s.propertyItem}>
                <Typography type="h5">Ground Rent Price (per year) €</Typography>
                <div>
                  <Form.Item name="groundRentPrice">
                    <Input.Number
                      name="groundRentPrice"
                      size="large"
                      className={isRequireRentPrice && s.inputError}
                      formatter={(value) =>
                        value ? `€ ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : ''
                      }
                      parser={(value) => (value ? value.replace(/€\s?|,/g, '') : '')}
                      style={{ backgroundColor: 'white', width: '50%', borderRadius: 6 }}
                    />
                  </Form.Item>
                  {isRequireRentPrice && (
                    <Typography className={s.errorMessage}>This field is required</Typography>
                  )}
                </div>
              </div>
            )}
            <div className={s.Submit}>
              <Button
                type="primary"
                loading={creating || updating}
                disabled={isRequirePrice || isRequiredName || isRequireRentPrice || !isDirty1}
                htmlType="button"
                onClick={() => handleSubmit('basicInfo')}
                style={{ width: 300, height: 50 }}>
                {activeProperty?.id ? 'EDIT' : 'SAVE PROPERTY'}
              </Button>
            </div>
          </div>
          <div className={s.formBlock}>
            <div className={s.anchor} ref={contentRef2}></div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <span className={s.subTitle}>2</span>
              <Typography type="h4">Address</Typography>
            </div>
            <PropertyLocationVenue
              locationData={locationData!}
              setLocationData={setLocationData}
              isRequiredPostcode={isRequiredPostcode}
              isRequiredStreet={isRequiredStreet}
              isRequiredTown={isRequiredTown}
              lon={
                activeProperty?.coordinates?.longitude
                  ? activeProperty?.coordinates?.longitude
                  : locationData?.latLng?.lng
              }
              lat={
                activeProperty?.coordinates?.latitude
                  ? activeProperty?.coordinates?.latitude
                  : locationData?.latLng?.lat
              }
            />
            <div className={s.propertyItem}>
              <Typography type="h5">Orientation</Typography>
              <Form.Item name="orientationType" initialValue={OrientationTypeEnum.North}>
                <Radio.Group
                  className={s.filterSwitch}
                  options={orientationType}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </div>
            <div className={s.Submit}>
              <Button
                type="primary"
                loading={creating || updating}
                disabled={
                  isRequiredStreet ||
                  isRequiredTown ||
                  isRequiredPostcode ||
                  !activeProperty?.id ||
                  !isDirty2
                }
                htmlType="button"
                onClick={() => handleSubmit('address')}
                style={{ width: 300, height: 50 }}>
                SAVE
              </Button>
            </div>
          </div>
          <div className={s.formBlock}>
            <div className={s.anchor} ref={contentRef3}></div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <span className={s.subTitle}>3</span>
              <Typography type="h4">Property details</Typography>
            </div>
            {selectedCommercialType === CommercialTypeEnum.Residential &&
              selectedType.toLowerCase() !== 'plot' && (
                <div className={s.propertyItemStart}>
                  <Typography type="h5">Finish Type</Typography>
                  <Form.Item name="finishStatus" initialValue="finished">
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value="finished">Finished</Radio>
                        <Radio value="partlyFinished">Partly Finished</Radio>
                        <Radio value="shell">Shell</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </div>
              )}
            {propertyInfo?.map(
              (item, index) =>
                selectedType.toLowerCase() === item.name.split(',')[0].trim().toLowerCase() && (
                  <div key={index} className={s.parameterTypes}>
                    {item.parameterTypes.map(
                      (parameterType, subIndex) =>
                        parameterType.name !== 'phone' && (
                          <div key={subIndex}>
                            <Label>{parameterType.caption}</Label>
                            <Form.Item name={['parameters', parameterType.id]}>
                              <Input
                                style={{ backgroundColor: 'white', width: '100%' }}
                                name={parameterType.id.toString()}
                              />
                            </Form.Item>
                          </div>
                        ),
                    )}
                  </div>
                ),
            )}

            <div className={s.propertyItemStart}>
              <Typography type="h5">Special Features</Typography>
              <Form.Item name="specialFeatures">
                <Checkbox.Group style={{ width: '100%', marginLeft: 8 }}>
                  {propertyInfo?.map(
                    (item, index) =>
                      item.name.toLowerCase() === selectedType.toLowerCase() && (
                        <Space key={index} direction="vertical">
                          {item.specialFeatures.map((specialFeature, subIndex) => (
                            <Checkbox key={subIndex} value={specialFeature.id}>
                              {specialFeature.name}
                            </Checkbox>
                          ))}
                        </Space>
                      ),
                  )}
                </Checkbox.Group>
              </Form.Item>
            </div>

            <div className={s.propertyCommentItem}>
              <Typography type="h5">About Property</Typography>
              <Form.Item name="comments">
                <TextArea className={s.comments} name="comments" rows={7} />
              </Form.Item>
            </div>
            <div className={s.Submit}>
              <Button
                type="primary"
                loading={creating || updating}
                disabled={!activeProperty?.id || !isDirty3}
                htmlType="button"
                onClick={() => handleSubmit('details')}
                style={{ width: 300, height: 50 }}>
                SAVE
              </Button>
            </div>
          </div>
          <div className={s.formBlock}>
            <div className={s.anchor} ref={contentRef4}></div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <span className={s.subTitle}>4</span>
              <Typography type="h4">Property space, photos and files</Typography>
            </div>

            <div style={{ marginTop: 24, width: '50%' }}>
              <Label>Total size m²</Label>
              <Form.Item name="totalSize">
                <Input style={{ backgroundColor: 'white', width: '100%' }} name="totalSize" />
              </Form.Item>
            </div>
            {activeProperty && (
              <div>
                <div className={s.uploadedFiles}>
                  <div style={{ marginTop: 8, marginBottom: 8 }}>
                    <Typography type="h5">PHOTOS</Typography>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    {activeProperty?.images?.map((file) => (
                      <div key={file.id} className={s.uploadedFile}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                          {file.imageURL.endsWith('.mp4') ? (
                            <video width={64} height={40}>
                              <source src={file.imageURL} type="video/mp4" />
                              <track
                                kind="captions"
                                src="path-to-captions.vtt"
                                srcLang="en"
                                label="English"
                                default
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              className={s.uploadedImage}
                              src={file.imageURL}
                              width={120}
                              height={80}
                              alt="uploadedImage"
                            />
                          )}
                        </div>
                        <Button
                          disabled={removing}
                          className={s.deleteButton}
                          type="link"
                          onClick={() => handleDeleteMedia(file.id)}>
                          <Icon name="trashBox" size={14} />
                        </Button>
                      </div>
                    ))}
                  </div>
                  {loading && (
                    <div className={s.fileUpload}>
                      <div
                        className={`${s.fileContent} ${loading === 'uploading' ? s.fill : ''} ${
                          loading === 'done' ? s.done : ''
                        }`}
                      />
                    </div>
                  )}
                </div>
                <div className={s.uploadWrapper}>
                  <Typography type="h3" weight={700}>
                    Files
                  </Typography>
                  <Label>This can only be viewed by Premium users</Label>
                  <div className={s.upload}>
                    <Upload
                      hasControlInside={true}
                      customRequest={customRequest}
                      accept=".jpg, .jpeg, .png, .gif, .mp4"
                      maxCount={1}
                      showUploadList={false}
                      beforeUpload={(file) => {
                        const isImageOrVideo = file.type.startsWith('image/');
                        // setFileType(file.type.startsWith('image/') ? 'image' : 'video');
                        if (!isImageOrVideo) {
                          message.error('Only image files can be uploaded!');
                        }
                        return isImageOrVideo;
                      }}
                      onChange={(info) => {
                        if (info.file.status === 'uploading') {
                          setLoading('uploading');
                          return;
                        }
                        if (info.file.status === 'done') {
                          message.success(`${info.file.name} successfully uploaded!`);
                          setLoading('done');
                        } else if (info.file.status === 'error') {
                          message.error(`${info.file.name} upload failed.`);
                        }
                      }}>
                      <div className={s.uploadBlock}>
                        <Button
                          htmlType="button"
                          loading={loading === 'uploading'}
                          className={s.chooseButton}>
                          CHOOSE FILES
                        </Button>
                        <Typography type="main" color="grey">
                          This can only be viewed by Premium users
                        </Typography>
                      </div>
                    </Upload>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={s.formBlock}>
            <div className={s.anchor} ref={contentRef5}></div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <span className={s.subTitle}>5</span>
              <Typography type="h4">Contact details</Typography>
            </div>
            <div className={s.propertyItem}>
              <Typography type="h5">Owner’s contacts visibility</Typography>
              <Form.Item name="ownerVisibility" initialValue="public">
                <Radio.Group
                  className={s.filterSwitch}
                  options={contactVisibilityTypeOption}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </div>
            <div className={s.propertyItem}>
              <Typography type="h5">Phone</Typography>
              <Form.Item name="phone">
                <PhoneInput
                  containerClass={s.phoneContainer}
                  inputClass={s.phone}
                  buttonClass={s.phoneButton}
                  regions="europe"
                  preferredCountries={['mt']}
                  country="mt"
                  localization={mt}
                />
              </Form.Item>
            </div>
            <div className={s.propertyItem}>
              <Typography type="h5">E-mail</Typography>
              <Form.Item name="email">
                <Input name="email" fullWidth />
              </Form.Item>
            </div>
            <Divider />
            <div className={s.inviteWrapper}>
              <Typography type="h5">Invite agent to manage this property</Typography>
              <div className={s.inviteInput}>
                <Form.Item name="agentEmail" style={{ width: 400 }}>
                  <Input name="agentEmail" fullWidth />
                </Form.Item>
                <Button type="primary" style={{ borderRadius: 4 }}>
                  INVITE
                </Button>
              </div>
            </div>
          </div>
          <div className={s.Submit}>
            <Button
              style={{ width: 300, height: 50 }}
              loading={creating || updating}
              type="primary"
              htmlType="button"
              onClick={() => handleSubmit('last')}>
              {activeProperty ? 'SAVE' : 'CREATE'}
            </Button>
          </div>
        </Form>
      </createPropertyContext.Provider>
    </div>
  );
};
export default PostProperty;
