import { FC, useCallback, useEffect, useState } from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import DownloadSwitcher from 'component/download-switcher';
import SectionTitle from 'component/section-title/sectionTitle';
import { Label, Modal, TextArea, Typography } from 'ui';

import { useLazyExportAttendeesQuery } from 'services/events/eventsApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

import EventPopover from 'containers/event-popover';

import { UserEventType } from 'types/core/userTypes';

import s from './Orders.module.scss';
// import Attendees from './attendees/eventAttendeesList';
import OrdersList from './orders-list';
import Transactions from './transactions/transactions';

const { TabPane } = Tabs;

const Orders: FC = () => {
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [exportAttendees] = useLazyExportAttendeesQuery();
  const [activeEvent, setActiveEvent] = useState<UserEventType>();
  const [isDownLoading, setIsDownLoading] = useState<boolean>();
  const [isPDF, setIsPDF] = useState<boolean>(false);
  const [tabKey, setTabKey] = useState<string>('order');

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleDownloadAttendees = useCallback(() => {
    setIsDownLoading(true);
    if (activeEvent?.eventId) {
      exportAttendees({ id: activeEvent?.eventId }).then((data) => {
        if (data.data) {
          const link = document.createElement('a');
          link.href = data.data?.fileName;
          link.setAttribute('download', `attendees-${activeEvent?.eventId}.csv`);
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIsDownLoading(false);
        }
      });
    }
  }, [exportAttendees, activeEvent?.eventId]);

  const handleSetActiveEvent = useCallback((event: UserEventType) => {
    setActiveEvent(event);
  }, []);

  useEffect(() => {
    const storedEvent = localStorage.getItem('currentEvent');
    if (storedEvent) {
      setActiveEvent(JSON.parse(storedEvent));
    }
  }, []);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    const storedEvent = localStorage.getItem('currentEvent');
    if (storedEvent) {
      setActiveEvent(JSON.parse(storedEvent));
    } else if (currentUser?.events?.length) {
      setActiveEvent(currentUser?.events[0]);
    }
  }, [currentUser, setActiveEvent]);

  const handleTabChange = (key) => {
    setTabKey(key);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.row}>
        <SectionTitle label="common_tickets_orders" className={s.title} />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 12 }}>
          <EventPopover handleEvent={handleSetActiveEvent} />
          <Button
            size="large"
            loading={isDownLoading}
            style={{ fontSize: 13 }}
            icon={<DownloadOutlined />}
            type="primary"
            onClick={showModal}>
            EXPORT
          </Button>
        </div>
      </div>

      <Modal
        open={open}
        onOk={handleOk}
        handleClose={handleCancel}
        footer={
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
            }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                icon={<DownloadOutlined />}
                loading={isDownLoading}
                type="primary"
                size="large"
                onClick={handleDownloadAttendees}
                style={{ fontSize: 13 }}>
                DOWNLOAD
              </Button>
              <Button style={{ fontSize: 13 }} size="large" onClick={handleCancel}>
                CANCEL
              </Button>
            </div>
            <Label>Please disable all popup blockers</Label>
          </div>
        }>
        <Modal.Header>
          {tabKey === 'order' ? (
            <Typography type="h3" weight={700}>
              Choose file type for Attendee list
            </Typography>
          ) : (
            <Typography type="h3" weight={700}>
              Choose file type for Transaction list
            </Typography>
          )}
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <DownloadSwitcher value={isPDF} onChange={setIsPDF} />
          </div>
        </Modal.Header>
        {isPDF ? (
          <Typography type="h6">
            PDF is a nicely formatted document showing the name on the ticket and who ordered it.
          </Typography>
        ) : (
          <Typography type="h6">
            CSV — {tabKey === 'order' ? 'Attendee' : 'Transaction'} list which includes all
            important fields including, if available, user address, phone numbers, and other
            information requested by event in comma delimited format.
          </Typography>
        )}

        <div style={{ marginTop: 16, marginBottom: 24 }}>
          <Label>Ticket type (if you wish to show only specific ticket types)</Label>
          <TextArea rows={20} name="general_admission" placeholder="General Admission"></TextArea>
        </div>
      </Modal>

      <div className={s.list}>
        <Tabs
          defaultActiveKey="order"
          onChange={handleTabChange}
          tabBarStyle={{ color: '#8D949C' }}
          style={{ width: '100%' }}>
          <TabPane
            tab="All tickets sold"
            key="order"
            // style={{ backgroundColor: "red", color: "#000" }}
          >
            <OrdersList
              currency={activeEvent?.currency}
              eventId={activeEvent?.eventId}
              eventName={activeEvent?.eventName}
              flyer={activeEvent?.eventFlyer}
            />
          </TabPane>
          <TabPane tab="Transactions" key="transaction">
            {activeEvent?.eventId && <Transactions eventId={activeEvent?.eventId} />}
          </TabPane>
          {/* <TabPane tab="Attendees" key="3">
            {activeEvent?.eventId && <Attendees eventId={activeEvent?.eventId} />}
          </TabPane> */}
        </Tabs>
      </div>
    </div>
  );
};

export default Orders;
