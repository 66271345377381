import React, { FC, useCallback, useEffect, useState } from 'react';

import { Button, Col, Dropdown, Modal, Radio, Slider, Typography } from 'antd';
import type { MenuProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ABOUT_ROUTE, DASHBOARD_ROUTE, FAQ_ROUTE, PRICING_ROUTE } from 'routes/dashboard/list';
import { CREATE_PROPERTY_FORM_ROUTE } from 'routes/property/list';
import { PROFILE_ROUTE } from 'routes/user-management/list';
import { Checkbox, Form, Icon, Input, Select } from 'ui';

import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { setActiveProperty } from 'slices/propertySlice';
import { setProfile } from 'slices/userSlice';
import { useAppDispatch } from 'store/index';

import s from './NavBar.module.scss';

interface NavBarProps {
  initiallyLogged: boolean;
}

const NavBar: FC<NavBarProps> = () => {
  const [price, setPrice] = useState<number | number[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [getCurrentuser, { data: profile }] = useLazyGetCurrentUserQuery();
  const handleGoToProfile = useCallback(() => {
    navigate(`${PROFILE_ROUTE}?step=userProfile`);
  }, [navigate]);

  const goToDashboard = useCallback(() => {
    navigate(DASHBOARD_ROUTE);
  }, [navigate]);

  const handleGoToAbout = useCallback(() => {
    navigate(ABOUT_ROUTE);
  }, [navigate]);

  const handleGoToFaq = useCallback(() => {
    navigate(FAQ_ROUTE);
  }, [navigate]);

  const handleGoToPricing = useCallback(() => {
    navigate(PRICING_ROUTE);
  }, [navigate]);

  const goToProperty = useCallback(() => {
    dispatch(setActiveProperty(undefined));
    navigate(CREATE_PROPERTY_FORM_ROUTE);
  }, [navigate, dispatch]);

  useEffect(() => {
    getCurrentuser();
  }, [getCurrentuser]);

  useEffect(() => {
    if (profile) {
      dispatch(setProfile(profile));
    }
  }, [profile, dispatch]);

  const onChange = (value: number | number[]) => {
    console.log('onChange: ', value);
  };

  const onChangeComplete = (value: number | number[]) => {
    setPrice(value);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = useCallback(async (data) => {
    setIsModalOpen(false);
    console.log(data);
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////////////
  const formatCurrency = (value) => {
    return `€${value.toLocaleString()}`;
  };

  const getFormattedRange = (min, max) => {
    const minCurrency = min * 1000;
    const maxCurrency = max * 1000;

    return `${formatCurrency(minCurrency)} — ${formatCurrency(maxCurrency)}`;
  };

  const propertyFilter = [
    { label: 'Residential', value: 'residential' },
    { label: 'Commercial', value: 'commercial' },
  ];

  const postFilter = [
    { label: 'Rent', value: 'rent' },
    { label: 'Sale', value: 'sale' },
    { label: 'Coliving', value: 'co-living' },
  ];

  const rentFilter = [
    { label: 'FreeHold', value: 'freeHold' },
    { label: 'GroundRent', value: 'groundRent' },
  ];
  const typeRentFilter = [
    { label: 'Perpetual', value: 'perpetual' },
    { label: 'Temporary', value: 'temporary' },
  ];
  const orientationFilter = [
    { label: 'North', value: 'north' },
    { label: 'East', value: 'east' },
    { label: 'West', value: 'west' },
    { label: 'South', value: 'south' },
  ];

  const rentOptions = [
    {
      value: 'rent',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Icon name="rent" />
          Rent
        </div>
      ),
    },
    {
      value: 'buy',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Icon name="buy" />
          Buy
        </div>
      ),
    },
    {
      value: 'share',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Icon name="share" />
          Share
        </div>
      ),
    },
    {
      value: 'rent_buy',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name="rent" />
            <Icon name="buy" />
          </div>
          Rent or Buy
        </div>
      ),
    },
  ];

  const apartmentOptions = [
    {
      value: 'apartment',
      label: 'Apartment',
    },
    {
      value: 'house',
      label: 'House',
    },

    {
      value: 'commercial',
      label: 'Commercial',
    },

    {
      value: 'plot_field',
      label: 'Plot/Field',
    },
  ];

  const bedRoomOption = [
    {
      value: 1,
      label: '1 Bedroom',
    },
    {
      value: 2,
      label: '2 Bedrooms',
    },
    {
      value: 3,
      label: '3 Bedrooms',
    },
    {
      value: 4,
      label: '4 Bedrooms',
    },
    {
      value: 5,
      label: '5 Bedrooms',
    },
  ];

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <>
          <Slider
            range
            min={0}
            max={150}
            step={1}
            defaultValue={[20, 50]}
            onChange={onChange}
            onChangeComplete={onChangeComplete}
          />
        </>
      ),
    },
  ];

  return (
    <div
      className={`${s.entireWrapper} ${
        location.pathname === '/dashboard' ? s.dashboardNavbar : ''
      }`}>
      <div className={s.navWrapper}>
        <Button type="link" onClick={goToDashboard}>
          <Icon width={200} name="logo" />
        </Button>
        <div className={s.menu}>
          <Button
            type="link"
            className={`${s.menuItem} ${location.pathname === '/buy' ? s.active : ''}`}>
            Buy
          </Button>
          <Button
            type="link"
            className={`${s.menuItem} ${location.pathname === '/rent' ? s.active : ''}`}>
            Rent
          </Button>
          <Button
            type="link"
            onClick={handleGoToAbout}
            className={`${s.menuItem} ${location.pathname === '/about' ? s.active : ''}`}>
            About
          </Button>
          <Button
            type="link"
            onClick={handleGoToFaq}
            className={`${s.menuItem} ${location.pathname === '/faq' ? s.active : ''}`}>
            FAQ
          </Button>
          <Button
            type="link"
            onClick={handleGoToPricing}
            className={`${s.menuItem} ${location.pathname === '/pricing' ? s.active : ''}`}>
            Pricing
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Button className={s.post} onClick={goToProperty}>
            POST FOR FREE
          </Button>
          <Button onClick={handleGoToProfile} className={s.actions}>
            <Icon name="profile" />
            <span className={s.text}>MY PROFILE</span>
          </Button>
        </div>
      </div>
      {location.pathname === '/dashboard' && (
        <div className={s.searchForm}>
          <Select defaultValue="rent" options={rentOptions}></Select>
          <Select defaultValue="apartment" options={apartmentOptions}></Select>
          <Select defaultValue="1" options={bedRoomOption}></Select>
          <Dropdown menu={{ items }} placement="bottom" trigger={['click']}>
            <Button className={s.dropdownButton}>
              {!price ? 'Price' : getFormattedRange(price[0], price[1])}
            </Button>
          </Dropdown>
          <Button onClick={showModal} className={s.dropdownButton}>
            More Filters
          </Button>
          <Input className={s.searchInput} fullWidth name="search" placeholder="Street or Town" />
        </div>
      )}
      <Modal
        centered
        title={<div style={{ fontSize: 36, fontWeight: 700 }}>More filters</div>}
        open={isModalOpen}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}>
        <Form className={s.form} onFinish={handleSubmit} layout="vertical">
          <div className={s.filter}>
            <div className={s.modalItem}>
              <Typography style={{ fontSize: 16, width: '100%', fontWeight: 600 }}>
                Property Type
              </Typography>
              <div style={{ width: '100%' }}>
                <Form.Item name="propertyType" initialValue="residential">
                  <Radio.Group
                    className={s.filterSwitch}
                    options={propertyFilter}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </div>
            </div>
            <div className={s.modalItem}>
              <Typography style={{ fontSize: 16, width: '100%', fontWeight: 600 }}>
                Type of posting
              </Typography>
              <div style={{ width: '100%' }}>
                <Form.Item name="postType" initialValue="rent">
                  <Radio.Group
                    className={s.filterSwitch}
                    options={postFilter}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </div>
            </div>
            <div className={s.modalItem}>
              <Typography style={{ fontSize: 16, width: '100%', fontWeight: 600 }}>
                Ground Rent
              </Typography>
              <div style={{ width: '100%' }}>
                <Form.Item name="groundRent" initialValue="freeHold">
                  <Radio.Group
                    className={s.filterSwitch}
                    options={rentFilter}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </div>
            </div>
            <div className={s.modalItem}>
              <Typography style={{ fontSize: 16, width: '100%', fontWeight: 600 }}>
                Type of Ground Rent
              </Typography>
              <div style={{ width: '100%' }}>
                <Form.Item name="typeGroundRent" initialValue="perpetual">
                  <Radio.Group
                    className={s.filterSwitch}
                    options={typeRentFilter}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </div>
            </div>
            <div className={s.modalItem}>
              <Typography style={{ fontSize: 16, width: '100%', fontWeight: 600 }}>
                Orientation
              </Typography>
              <div style={{ width: '100%' }}>
                <Form.Item name="orientation" initialValue="north">
                  <Radio.Group
                    className={s.filterSwitch}
                    options={orientationFilter}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className={s.checkboxes} style={{ paddingTop: 24 }}>
            <div className={s.modalItem}>
              <Typography style={{ fontSize: 16, width: '100%', fontWeight: 600 }}>
                Finish Type
              </Typography>
              <div style={{ width: '100%' }}>
                <Col span={8}>
                  <Form.Item name="onPlan">
                    <Checkbox style={{ maxHeight: 30, width: 200 }}>
                      <span>On Plan</span>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="shell">
                    <Checkbox>Shell</Checkbox>
                  </Form.Item>
                  <Form.Item name="finished">
                    <Checkbox>Finished</Checkbox>
                  </Form.Item>
                </Col>
              </div>
            </div>
            <div className={s.modalItem}>
              <Typography style={{ fontSize: 16, width: '100%', fontWeight: 600 }}>
                Size m²
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                <Form.Item name="from">
                  <Input name="from" placeholder="From"></Input>
                </Form.Item>
                <Form.Item name="to">
                  <Input name="to" placeholder="To"></Input>
                </Form.Item>
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
              <Button className={s.cancel} onClick={handleCancel}>
                CANCEL
              </Button>
              <Button type="primary" className={s.result} htmlType="submit">
                SHOW RESULTS
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};
export default NavBar;
