import React, { useCallback } from 'react';

import { ConfigProvider } from 'antd';
import cn from 'classnames';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { getIsLoggedIn } from 'tools/cookieTools';
import { Icon } from 'ui';

import { isOpenedSiderSelector } from 'selectors/systemSlice.selector';
import { isLoggedInSelector } from 'selectors/userSlice.selectors';
import { setOpenedSider } from 'slices/systemSlice';
import { persistor, store, useAppDispatch, useAppSelector } from 'store';

import Footer from 'containers/footer';
import NavBar from 'containers/nav-bar';

import s from './App.module.scss';
import Pages from './pages';

function App() {
  const isAuth = getIsLoggedIn();
  const isLogged = useAppSelector(isLoggedInSelector);
  const initiallyLogged = isLogged || isAuth;
  const dispatch = useAppDispatch();
  const isOpenedSidebar = useAppSelector(isOpenedSiderSelector);

  const handleClick = useCallback(() => {
    dispatch(setOpenedSider(!isOpenedSidebar));
  }, [isOpenedSidebar, dispatch]);
  const keyPressHandler = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      return;
    }
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#8E53E8',
              borderRadius: 8,
              colorTextBase: '#000000',
              colorLinkHover: '#B989FF',
              colorLinkActive: '#000000',
            },
          }}>
          <div className="App">
            <div>
              <div className={s.content}>
                {initiallyLogged && (
                  <div className={cn(s.mobileHeader)}>
                    <button
                      className={cn(s.menu, isOpenedSidebar && s.rotate)}
                      onClick={handleClick}>
                      <div className={cn(s.menuLine, isOpenedSidebar && s.rotate)}></div>
                      <div className={cn(s.menuLine, isOpenedSidebar && s.rotate)}></div>
                    </button>
                    <div className={s.logo}>
                      <Icon name="logo" width={252} height={40} />
                    </div>
                  </div>
                )}
                <Pages />
                {initiallyLogged && <Footer />}
              </div>
              {initiallyLogged && (
                <>
                  <div className={s.navBar}>
                    <NavBar initiallyLogged={initiallyLogged} />
                  </div>
                  {isOpenedSidebar && (
                    <div
                      className={s.shadow}
                      role="button"
                      tabIndex={0}
                      onKeyPress={keyPressHandler}
                      onClick={() => {
                        dispatch(setOpenedSider(false));
                      }}></div>
                  )}
                </>
              )}
            </div>
          </div>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
