import { FC } from 'react';

import { PhoneFilled } from '@ant-design/icons';
import { Typography } from 'ui';

import s from './AgentCard.module.scss';

const AgentCard: FC = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.imageWrapper}>
        <img src="/assets/images/agents/c1.svg" width={60} height={60} alt="agent" />
        <div>
          <Typography type="h4">Alex Candy</Typography>
          <Typography type="label">Agent</Typography>
        </div>
      </div>
      <div className={s.infoWrapper}>
        <div className={s.agenciesImage}>
          <img src="/assets/images/agencies/Agency1.png" width={30} height={30} alt="agent" />
          <Typography color="primary" type="main" weight={700}>
            GOLDEN HOMES
          </Typography>
        </div>
        <div className={s.agenciesImage}>
          <PhoneFilled style={{ fontSize: 20, color: '#CED2E5' }} />
          <Typography type="h6" weight={700}>
            +356 7900 1234
          </Typography>
        </div>
      </div>
    </div>
  );
};
export default AgentCard;
