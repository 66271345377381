import React, { FC } from 'react';

import { Collapse, CollapseProps } from 'antd';
import { Typography } from 'ui';

import s from './Faq.module.scss';

const faqData = [
  {
    title: 'How do I list my property for sale or rent on your website?',
    content:
      "To list your property, navigate to the 'List Your Property' section and fill in the required details about your property, including location, price, and property features.",
  },
  {
    title: 'What information should I include in my property listing?',
    content:
      'Include essential details such as property type, size, location, amenities, and high-quality photos. Make sure to provide accurate and attractive descriptions to engage potential buyers or renters.',
  },
  {
    title: 'Is there a cost associated with listing my property?',
    content:
      'Listing a property is typically free, but we offer premium features for enhanced visibility, which may involve additional costs.',
  },
  {
    title: 'How can I edit or update my property listing?',
    content:
      'You can edit or update your listing at any time by logging into your account and selecting the property you wish to update from your dashboard.',
  },
  {
    title: 'How long will my property listing be active on the website?',
    content:
      'Property listings remain active until the property is sold or rented, or for a period of 6 months, after which you can renew your listing.',
  },
  {
    title: 'What security measures are in place to protect my personal information?',
    content:
      'We use advanced encryption and security protocols to ensure that your personal information is protected against unauthorized access and disclosure.',
  },
  {
    title: 'How do I communicate with potential buyers or renters?',
    content:
      'Our platform offers a secure messaging system that allows you to communicate directly with interested parties without disclosing personal contact information.',
  },
  {
    title: 'Can I feature my property to increase visibility?',
    content:
      'Yes, you can opt for our featured listing option, which gives your property higher visibility in search results and on our homepage.',
  },
  {
    title: 'Are there any guidelines for creating attractive property listings?',
    content:
      'Create a compelling title, provide a detailed description, add high-quality photos, and be clear about the amenities and unique features of your property.',
  },
  {
    title: 'What happens after I find a buyer or renter?',
    content:
      'Once you find a buyer or renter, you can proceed with the necessary agreements and transactions. We recommend consulting with a real estate professional for legal and transactional procedures.',
  },
];

const FAQ: FC = () => {
  const items: CollapseProps['items'] = faqData.map((faq, index) => ({
    key: `${index + 1}`,
    label: (
      <div className={s.faqItem}>
        <div className={s.numberWrapper}>{index + 1}</div>{' '}
        <Typography type="h3">{faq.title}</Typography>
      </div>
    ),
    children: <div className={s.faqContent}> {faq.content}</div>,
  }));

  return (
    <div className={s.wrapper}>
      <Typography type="h1" weight={900}>
        FAQ
      </Typography>
      <Typography type="h6">
        Here&apos;s a list of the most frequently asked questions, we are filling this section from
        time to time to keep all of the information updated
      </Typography>
      <div style={{ marginBottom: 48 }}></div>

      <Collapse defaultActiveKey={['1']} expandIconPosition="end" items={items} />
    </div>
  );
};

export default FAQ;
