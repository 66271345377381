import { FC, useState } from 'react';

import { Button } from 'antd';
import ImgCrop from 'antd-img-crop';
import { Label, Typography, Upload, message } from 'ui';

import { ProfileSelector } from 'selectors/userSlice.selectors';
import { useUploadProfilePictureMutation } from 'services/user-management/userManagementApiService';
import { setProfile } from 'slices/userSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import s from './UserProfile.module.scss';
import PasswordForm from './password-form';
import UserForm from './user-form';

const UserProfile: FC = () => {
  const [loading, setLoading] = useState<string>('');
  const profile = useAppSelector(ProfileSelector);

  const [uploadPropertyImage] = useUploadProfilePictureMutation();
  const dispatch = useAppDispatch();

  const customRequest = async (options) => {
    const { file, onError } = options;

    try {
      const toBase64 = (file: File) =>
        new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = (error) => reject(error);
        });

      const fileBase64 = await toBase64(file);

      // Create a payload with the base64 string and other fields

      const payload = {
        base64Image: fileBase64,
        fileName: file.name,
      };

      console.log(payload);
      const res = await uploadPropertyImage(payload);

      if ('data' in res) {
        message.success('Profile image successfully uploaded!');
        dispatch(setProfile(res.data));
        setLoading('done');
      }
    } catch (error) {
      onError(error);
    }
  };

  return (
    <div className={s.wrapper}>
      {' '}
      <Typography>Profile Details</Typography>
      <div className={s.content}>
        <div className={s.editUser}>
          <div className={s.profileImage}>
            <div className={s.uploadedFiles}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                {profile?.profileImageURL &&
                  profile?.profileImageURL !==
                    'https://onthatstreet.blob.core.windows.net/content/users/profilepictures/' && (
                    <div className={s.uploadedFile}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <img
                          className={s.uploadedImage}
                          src={profile?.profileImageURL}
                          width={64}
                          height={64}
                          alt="promotionMedia"
                        />
                      </div>
                    </div>
                  )}
                {!profile?.profileImageURL ||
                  (profile?.profileImageURL ===
                    'https://onthatstreet.blob.core.windows.net/content/users/profilepictures/' && (
                    <div className={s.uploadedFile}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <img
                          className={s.uploadedImage}
                          src="/assets/images/man.png"
                          width={64}
                          height={64}
                          alt="promotionMedia"
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <div>
                <Typography type="h5">{profile?.firstName}</Typography>
                <Label>From 2024</Label>
              </div>
              {loading && (
                <div className={s.fileUpload}>
                  <div
                    className={`${s.fileContent} ${loading === 'uploading' ? s.fill : ''} ${
                      loading === 'done' ? s.done : ''
                    }`}
                  />
                </div>
              )}
            </div>
            <div className={s.uploadWrapper}>
              <div className={s.upload}>
                <ImgCrop rotationSlider>
                  <Upload
                    hasControlInside={true}
                    customRequest={customRequest}
                    accept=".jpg, .jpeg, .png, .gif, .mp4"
                    maxCount={1}
                    showUploadList={false}
                    beforeUpload={(file) => {
                      const isImageOrVideo = file.type.startsWith('image/');
                      // setFileType(file.type.startsWith('image/') ? 'image' : 'video');
                      if (!isImageOrVideo) {
                        message.error('Only image files can be uploaded!');
                      }
                      return isImageOrVideo;
                    }}
                    onChange={(info) => {
                      if (info.file.status === 'uploading') {
                        setLoading('uploading');
                        return;
                      }
                      if (info.file.status === 'done') {
                        message.success(`${info.file.name} successfully uploaded!`);
                        setLoading('done');
                      } else if (info.file.status === 'error') {
                        message.error(`${info.file.name} upload failed.`);
                      }
                    }}>
                    <div className={s.uploadBlock}>
                      <Button
                        type="primary"
                        htmlType="button"
                        loading={loading === 'uploading'}
                        className={s.chooseButton}>
                        ADD PHOTO
                      </Button>
                    </div>
                  </Upload>
                </ImgCrop>
              </div>
            </div>
          </div>
          <UserForm />
        </div>
        <div className={s.password}>
          <Typography type="h4">Password</Typography>
          <div className={s.block}>
            <PasswordForm />
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserProfile;
