import { FC, useEffect, useState } from 'react';

import { HeartOutlined, StarOutlined, SyncOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
// import ImageGallery from 'react-image-gallery';
import Map, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
  ScaleControl,
} from 'react-map-gl';
import { useParams } from 'react-router-dom';
import { Icon, Label, Typography } from 'ui';
import PropertyCard from 'ui/propertyCard';

import { propertyListSelector } from 'selectors/propertySlice.selector';
import { useLazyGetPropertyDetailQuery } from 'services/properties/propertyApiService';
import { useAppSelector } from 'store/index';

import { GetPropertyType } from 'types/property/propertyTypes';

import '../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss';
import s from './PropertyDetail.module.scss';
import AgentCard from './agentCard';
import ImageGrid from './imageGrid';

const MAPBOX_TOKEN = process.env.REACT_APP_MAP_BOX_TOKEN;

const PropertyDetail: FC = () => {
  const { propertyId } = useParams();
  const propertyList = useAppSelector(propertyListSelector);
  const [getPropertyDetail, { data: PropertyDetail }] = useLazyGetPropertyDetailQuery();
  const [currentProperty, setCurrentProperty] = useState<GetPropertyType>();
  const [images, setImages] = useState<
    {
      original: string;
      thumbnail: string;
      originalHeight?: number;
      originalWidth?: number;
    }[]
  >();

  const [showMapStyle, setShowMapStyle] = useState('mapbox://styles/mapbox/streets-v12');

  const toggleMapStyle = () => {
    // Toggle between satellite and street view styles
    const newMapStyle =
      showMapStyle === 'mapbox://styles/mapbox/streets-v12'
        ? 'mapbox://styles/mapbox/satellite-streets-v12'
        : 'mapbox://styles/mapbox/streets-v12';

    setShowMapStyle(newMapStyle);
  };

  useEffect(() => {
    if (propertyId) {
      getPropertyDetail(propertyId);
    }
  }, [getPropertyDetail, propertyId]);

  useEffect(() => {
    const temp = propertyList?.find((item) => item.id === propertyId);
    if (temp) {
      setCurrentProperty(temp);
    }
  }, [propertyId, propertyList]);

  useEffect(() => {
    if (PropertyDetail) {
      const tempImages = PropertyDetail?.images!.map((item) => ({
        original: item?.imageURL ? item?.imageURL : '/assets/images/no-image.png',
        thumbnail: item?.imageURL ? item?.imageURL : '/assets/images/no-image.png',
      }));

      setImages(tempImages);
    }
  }, [PropertyDetail]);

  return (
    <div className={s.wrapper}>
      <Typography type="h2">{currentProperty?.name}</Typography>
      <Label className={s.Label}>{currentProperty?.address?.street}</Label>
      <div className={s.buttonGroup}>
        <Button type="link" className={s.linkButton}>
          <Icon name="map" /> SHOW ON MAP
        </Button>
        <Button type="link" className={s.linkButton}>
          <UploadOutlined style={{ fontSize: 20 }} /> SHARE PAGE
        </Button>
        <Button type="link" className={s.linkButton}>
          <HeartOutlined style={{ fontSize: 20 }} /> SAVE TO FAVOURITES
        </Button>
      </div>
      <div className={s.content}>
        <div className={s.imageGallery}>
          {/* {images && <ImageGallery items={images} />} */}
          {images && <ImageGrid images={images} />}
          <div className={s.field}>
            <Typography className={s.fieldTitle}>About</Typography>
            <Typography type="main">{PropertyDetail?.comments}</Typography>
          </div>
          <div className={s.field}>
            {PropertyDetail?.parameters && (
              <Typography className={s.fieldTitle}>Parameters</Typography>
            )}
            {PropertyDetail?.parameters &&
              PropertyDetail?.parameters.map((item, index) => (
                <div key={index} className={s.parameter}>
                  <Typography className={s.parameterlabel}>{item.name}</Typography>
                  <Typography type="h6">{item.value}</Typography>
                </div>
              ))}
          </div>
          <div className={s.field}>
            {PropertyDetail?.specialFeatures && (
              <Typography className={s.fieldTitle}>Special characteristics</Typography>
            )}
            <div className={s.specialParameter}>
              {PropertyDetail?.specialFeatures &&
                PropertyDetail?.specialFeatures.map((item, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <StarOutlined style={{ fontSize: 20 }} />
                    <Typography className={s.parameterlabel}>{item.name}</Typography>
                  </div>
                ))}
            </div>
          </div>
          <div className={s.field}>
            {currentProperty?.coordinates && <Typography className={s.fieldTitle}>Map</Typography>}
            {currentProperty?.coordinates && (
              <>
                <div className={s.infoLocation}>
                  <div className={s.mapWrapper}>
                    <Button className={s.changeButton} onClick={toggleMapStyle}>
                      <SyncOutlined />
                    </Button>
                    <Map
                      initialViewState={{
                        longitude: 14.5146, // Center the map around Malta's coordinates
                        latitude: 35.8989,
                        zoom: 11,
                        bearing: 0,
                        pitch: 0,
                      }}
                      mapStyle={showMapStyle}
                      mapboxAccessToken={MAPBOX_TOKEN}>
                      <GeolocateControl position="bottom-right" />
                      <FullscreenControl position="bottom-right" />
                      <NavigationControl position="bottom-right" />
                      <ScaleControl />

                      <Marker
                        longitude={currentProperty?.coordinates?.longitude || 14.5146}
                        latitude={currentProperty?.coordinates?.latitude || 35.8989}
                        // longitude={14.5146} // Center the map around Malta's coordinates
                        // latitude={35.8989}
                        anchor="bottom"></Marker>
                    </Map>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={s.field}>
            <Typography className={s.fieldTitle}>Could be interesting</Typography>
            <div className={s.interesting}>
              {propertyList?.map((property, index) =>
                index < 5 ? (
                  <PropertyCard editable={false} key={index} property={property} />
                ) : (
                  <></>
                ),
              )}
            </div>
          </div>
        </div>
        <div className={s.agent}>
          <AgentCard />
        </div>
      </div>
    </div>
  );
};
export default PropertyDetail;
