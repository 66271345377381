import { createContext } from 'react';

import { FormInstance } from 'ui';

import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

import {
  CommercialTypeEnum,
  GroundRentTypeEnum,
  OrientationTypeEnum,
  PostingTypeEnum,
  SaleStatusEnum,
} from 'types/property/propertyEnum';

import { PreparePropertyLocationVenueResult } from './property-location-venue/propertyLocationVenue.utils';

const mapboxClient = mbxGeocoding({ accessToken: process.env.REACT_APP_MAP_BOX_TOKEN });

export const statusOption = [
  { label: 'AVAILABLE', value: SaleStatusEnum.Available },
  { label: 'NOT AVAILABLE', value: SaleStatusEnum.Sold },
];

export const propertyTypeOption = [
  { label: 'RESIDENTIAL', value: CommercialTypeEnum.Residential },
  { label: 'COMMERCIAL', value: CommercialTypeEnum.Commercial },
];

export const contactVisibilityTypeOption = [
  { label: 'PUBLIC', value: 'public' },
  { label: 'PRIVATE', value: 'private' },
];

export const postTypeOption = [
  { label: 'SALE', value: PostingTypeEnum.Sale },
  { label: 'RENT', value: PostingTypeEnum.Rent },
];

export const orientationType = [
  { label: 'NORTH', value: OrientationTypeEnum.North },
  { label: 'EAST', value: OrientationTypeEnum.East },
  { label: 'SOUTH', value: OrientationTypeEnum.South },
  { label: 'WEST', value: OrientationTypeEnum.West },
];

export const groundRentTypeOption = [
  { label: 'FREEHOLD', value: GroundRentTypeEnum.FreeHold },
  { label: 'GROUND RENT', value: GroundRentTypeEnum.GroundRent },
];

export type HandleChangeType = (
  changedValues: Partial<Record<string, PreparePropertyLocationVenueResult>>,
  allValues: FormInstance['getFieldsValue'],
) => void;

interface createPropertyContextInterface {
  form?: FormInstance;
  onChangeForm?: HandleChangeType;
}

export const createPropertyContext = createContext<createPropertyContextInterface>({});

export const getVicinity = async (lat: number, lon: number): Promise<string | null> => {
  try {
    const response = await mapboxClient
      .reverseGeocode({
        query: [lon, lat],
        types: [
          'country',
          'region',
          'postcode',
          'district',
          'place',
          'locality',
          'neighborhood',
          'address',
          'poi',
        ],
      })
      .send();

    const features = response.body.features;
    console.log(features[0]?.place_name);
    if (features && features.length > 0) {
      const detailedFeature =
        features.find((f) => f.place_type.includes('address') || f.place_type.includes('street')) ||
        features[0];

      const placeName = detailedFeature.place_name;
      const parts = placeName.split(',');

      if (parts.length > 1) {
        return parts.slice(1).join(',').trim();
      } else {
        return placeName;
      }
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching vicinity:', error);
    return null;
  }
};
