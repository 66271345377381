import React, { FC, memo } from 'react';

import { t } from 'tools/i18n';
import { Button, Select, Typography } from 'ui';

import s from './Pagination.module.scss';

interface PaginationProps {
  totalElements?: number;
  total?: number;
  currentPage: number;
  showedElements: number | string;
  handlePageChange: (page: number) => void;
  handleSizeChange?: (size: number) => void;
  defaultPageSize?: number;
}

const Pagination: FC<PaginationProps> = (props) => {
  const {
    totalElements,
    currentPage,
    total,
    handlePageChange,
    showedElements,
    handleSizeChange,
    defaultPageSize = 100,
  } = props;

  const disablePaginationOnError =
    isNaN(Number(totalElements)) ||
    isNaN(currentPage) ||
    !Number(total) ||
    isNaN(Number(showedElements));

  const handlePageSizeChange = (size) => {
    if (handleSizeChange) {
      handleSizeChange(size?.value);
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={s.sizing}>
        <Typography type="main" color="secondary">
          {t('common_pagination_info', {
            showedElements: showedElements || 0,
            totalElements: totalElements || 0,
          })}
        </Typography>
        {!!handleSizeChange && (
          <Select
            options={[
              { value: 100, label: '100' },
              { value: 200, label: '200' },
              { value: 300, label: '300' },
            ]}
            defaultValue={{ value: defaultPageSize, label: `${defaultPageSize}` }}
            handleSelect={handlePageSizeChange}
          />
        )}
      </div>
      <div className={s.actionGroup}>
        <Button
          size="small"
          color="tertiary"
          disabled={disablePaginationOnError || currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}>
          {t('common_previous')}
        </Button>
        <Button
          size="small"
          color="tertiary"
          disabled={disablePaginationOnError || currentPage === total}
          onClick={() => handlePageChange(currentPage + 1)}>
          {t('common_next')}
        </Button>
      </div>
    </div>
  );
};

export default memo(Pagination);
