import { FC } from 'react';

import classNames from 'classnames';
import { Typography } from 'ui';
import PropertyCard from 'ui/propertyCard';

import { propertyListSelector } from 'selectors/propertySlice.selector';
import { useAppSelector } from 'store/index';

import s from './Properties.module.scss';

const Properties: FC = () => {
  const propertyList = useAppSelector(propertyListSelector);

  return (
    <div className={s.wrapper}>
      <Typography>Your properties</Typography>

      <div
        className={classNames(s.components, {
          [s['more-than-4']]: propertyList!.filter((item) => item.isEditable === true).length > 4,
        })}>
        {propertyList &&
          propertyList
            .filter((item) => item.isEditable === true)
            .map((item, index) => <PropertyCard key={index} property={item} editable={true} />)}
      </div>
    </div>
  );
};
export default Properties;
