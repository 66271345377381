import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { SyncOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, MenuProps } from 'antd';
import cn from 'classnames';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import Map, {
  FullscreenControl,
  GeolocateControl,
  MapRef,
  Marker,
  NavigationControl,
  ScaleControl,
} from 'react-map-gl';
import { useClickOutside } from 'tools/hooks';
import { t } from 'tools/i18n';
import { Form, Input, Label, Select, Typography } from 'ui';

import { getVicinity } from '../postProperty.utils';
// import { formRules } from 'utils/formRules';
import { createPropertyContext } from '../postProperty.utils';
import s from './PropertyLocationVenue.module.scss';
import {
  PreparePropertyLocationVenueResult,
  preparePropertyLocationVenue,
} from './propertyLocationVenue.utils';

const MAPBOX_TOKEN = process.env.REACT_APP_MAP_BOX_TOKEN;

interface PropertyLocationVenueProps {
  locationData: PreparePropertyLocationVenueResult;
  setLocationData: Dispatch<SetStateAction<PreparePropertyLocationVenueResult | undefined>>;
  isRequiredStreet: boolean;
  isRequiredTown: boolean;
  isRequiredPostcode: boolean;
  lon?: number;
  lat?: number;
}

const PropertyLocationVenue: FC<PropertyLocationVenueProps> = ({
  setLocationData,
  isRequiredStreet,
  isRequiredTown,
  isRequiredPostcode,
  lon,
  lat,
}) => {
  const { form, onChangeForm } = useContext(createPropertyContext);
  const mapRef = useRef<MapRef | null>(null);

  const ref = useRef(null);
  const [openLocationDropdown, setOpenLocationDropdown] = useState<boolean>(false);
  const [isCustomAddress, setIsCustomAddress] = useState<boolean>(false);
  const [venueValue, setVenueValue] = useState<string>('');
  const [searchIsDirty, setSearchIsDirty] = useState<boolean>(false);
  const [markerPosition, setMarkerPosition] = useState({
    longitude: lon ? lon : 14.5146,
    latitude: lat ? lat : 35.8989,
  });
  const selectedAddress = Form.useWatch('address', form);
  const selectedCountry = Form.useWatch(['address', 'country'], form);

  const [userInitCountry, setUserInitCountry] = useState<string>('');
  const [dragged, setDragged] = useState<boolean>(false);
  // const { data: currencies } = useGetAvailableCurrenciesQuery();

  const [countries, setCountries] = useState<
    { name: string; flag: string; code?: string; latlng: string[] }[]
  >([]);
  const selectedCountryCode = countries?.find((country) => country.name === 'Malta')?.code;

  const [showMapStyle, setShowMapStyle] = useState('mapbox://styles/mapbox/streets-v12');

  const toggleMapStyle = () => {
    // Toggle between satellite and street view styles
    const newMapStyle =
      showMapStyle === 'mapbox://styles/mapbox/streets-v12'
        ? 'mapbox://styles/mapbox/satellite-streets-v12'
        : 'mapbox://styles/mapbox/streets-v12';

    setShowMapStyle(newMapStyle);
  };

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading, placesService } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

  const handleSelectAddress = useCallback(
    (id: string, name: string) => {
      placesService?.getDetails({ placeId: id }, (placeDetails) => {
        const lat = placeDetails.geometry.location.lat();
        const lng = placeDetails.geometry.location.lng();

        setMarkerPosition({ longitude: lng, latitude: lat });

        if (mapRef.current) {
          mapRef.current.flyTo({
            center: [lng, lat],
            zoom: 18,
            duration: 2000,
            essential: true,
          });
        }
        form?.setFieldValue('address', preparePropertyLocationVenue(placeDetails, name));
        console.log(placeDetails);
        setLocationData(preparePropertyLocationVenue(placeDetails, name));
        if (onChangeForm) {
          onChangeForm(
            { address: preparePropertyLocationVenue(placeDetails, name) },
            form?.getFieldsValue(),
          );
        }
      });
      setVenueValue(name);
      setOpenLocationDropdown(false);
      setSearchIsDirty(false);
      setIsCustomAddress(false);
    },
    [form, onChangeForm, placesService, setLocationData],
  );

  const getUserCountry = useCallback(async (ip) => {
    const res = await fetch(`https://api.iplocation.net/?cmd=ip-country&ip=${ip}`);

    const data: {
      country_code2: string;
      country_name: string;
      ip: string;
      ip_number: string;
      ip_version: number;
      isp: string;
      response_code: string;
      response_message: string;
    } = await res.json();

    if (data?.country_name) {
      setUserInitCountry(data.country_name);
    }
  }, []);

  const getCountries = useCallback(async () => {
    const responseCountries = await fetch('https://restcountries.com/v3.1/all');
    const data = await responseCountries.json();
    fetch('https://api64.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        const ipAddress = data.ip;
        if (ipAddress) {
          getUserCountry(ipAddress);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    setCountries(
      data
        ?.map((item) => ({
          name: item.name.common,
          flag: item.flag,
          code: item?.altSpellings[0],
          latlng: item?.capitalInfo?.latlng,
        }))
        .sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
    );
  }, [getUserCountry]);

  useClickOutside({
    ref,
    handler: () => {
      if (openLocationDropdown) {
        setOpenLocationDropdown(false);
        setSearchIsDirty(false);
      }
    },
  });

  const handleMarkerDragEnd = async (event: any) => {
    const { lngLat } = event;

    try {
      setMarkerPosition({
        latitude: lngLat.lat,
        longitude: lngLat.lng,
      });
      console.log('New position:', lngLat.lat, lngLat.lng);

      const vicinityResult = await getVicinity(lngLat.lat, lngLat.lng);
      console.log('Vicinity result:', vicinityResult);

      if (vicinityResult) {
        getPlacePredictions({
          input: vicinityResult,
          componentRestrictions: {
            country: selectedCountryCode?.toLowerCase() || '',
          },
        });
        setDragged(true);
        setSearchIsDirty(true);
        setVenueValue(vicinityResult);
        setOpenLocationDropdown(true);

        if (!vicinityResult) {
          setSearchIsDirty(false);
        }

        form?.setFieldValue(['address', 'venueName'], vicinityResult);
      } else {
        console.warn('No vicinity result found.');
        setSearchIsDirty(false);
      }
    } catch (error) {
      console.error('Error in handleMarkerDragEnd:', error);
    }
  };

  useEffect(() => {
    if (placePredictions.length > 0 && dragged && !isPlacePredictionsLoading) {
      console.log(placePredictions);
      console.log(dragged);
      placesService?.getDetails({ placeId: placePredictions[0]?.place_id }, () => {
        if (mapRef.current) {
          mapRef.current.flyTo({
            center: [markerPosition?.longitude, markerPosition?.latitude],
            zoom: 18,
            duration: 2000,
            essential: true,
          });
        }
      });
      setVenueValue(placePredictions[0]?.description);
      setOpenLocationDropdown(false);
      setSearchIsDirty(false);
      setIsCustomAddress(false);
      setDragged(false);
    }
  }, [
    placePredictions,
    dragged,
    onChangeForm,
    isPlacePredictionsLoading,
    placesService,
    markerPosition,
    setLocationData,
  ]);

  const handleSetCustomAddress = () => {
    setIsCustomAddress(true);
  };

  const placesList: MenuProps['items'] = useMemo(() => {
    const result = placePredictions?.map((place) => ({
      label: (
        <button
          onClick={() => handleSelectAddress(place?.place_id, place?.description)}
          className={s.item}>
          {place.description}
        </button>
      ),
      key: place.place_id,
    }));

    if (result?.length) {
      result.push({
        label: (
          <button className={cn(s.item, s.customAddress)} onClick={handleSetCustomAddress}>
            {t('event_create_form_enter_custom_address')}
          </button>
        ),
        key: 'custom',
      });
    }

    if (!result.length && searchIsDirty && !isPlacePredictionsLoading) {
      return [
        {
          label: (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>{t('event_create_form_no_results')}</span>}>
              <button className={cn(s.item, s.customAddress)} onClick={handleSetCustomAddress}>
                {t('event_create_form_enter_custom_address')}
              </button>
            </Empty>
          ),
          key: 0,
        },
      ];
    } else {
      return result;
    }
  }, [handleSelectAddress, isPlacePredictionsLoading, placePredictions, searchIsDirty]);

  const handleChangeInput = (e) => {
    getPlacePredictions({
      input: e.target.value,
      componentRestrictions: { country: selectedCountryCode?.toLowerCase() },
    });
    setSearchIsDirty(true);
    setVenueValue(e.target.value);
    if (e.target.value === '') {
      setSearchIsDirty(false);
    }

    if (isCustomAddress) {
      form?.setFieldValue(['address', 'venueName'], e.target.value);
    }
  };

  useEffect(() => {
    // const location = activeEditingEvent?.basicInfo?.location;
    // // if (location?.fullAddress) {
    // //   setVenueValue(activeEditingEvent?.basicInfo?.location?.fullAddress || '');
    // // }
    // if (location?.fullAddress) {
    //   form?.setFieldValue(['location', 'fullAddress'], location?.fullAddress);
    // }

    // if (location?.streetAddress) {
    //   form?.setFieldValue(['location', 'streetAddress'], location?.streetAddress);
    // }

    // if (location?.town) {
    //   form?.setFieldValue(['location', 'town'], location?.town);
    // }

    // if (location?.postCode) {
    //   form?.setFieldValue(['location', 'postCode'], location?.postCode);
    // }

    // if (location?.country) {
    //   form?.setFieldValue(['location', 'country'], { value: location?.country });
    // } else {
    form?.setFieldValue(['location', 'country'], { value: userInitCountry });
    // }
  }, [form, userInitCountry]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  // const userCountry = countries.find((country) => country.name === selectedCountry?.value);

  return (
    <div>
      <div style={{ marginBottom: 24, marginTop: 16 }}>
        <Typography type="small" className={cn(s.label)}>
          Country
        </Typography>
        <Form.Item
          name={['address', 'country']}
          // rules={[formRules.required]}
          fullWidth
          initialValue="Malta">
          <Select
            disabled
            options={countries?.map((item) => ({
              value: item.name,
              label: `${item.flag} ${item.name}`,
            }))}
            showSearch
            filterOption={(input, option) =>
              String(option?.label ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            fullWidth
          />
        </Form.Item>
      </div>
      {selectedCountry && (
        <div ref={ref} className={s.wrapper}>
          <Typography type="small" className={cn(s.label)}>
            Search for street
          </Typography>

          <div className={s.location}>
            <Dropdown
              menu={{ items: placesList }}
              open={!!placesList.length && openLocationDropdown}>
              <Form.Item name={['address', 'fullAddress']} fullWidth>
                <Input
                  name="test"
                  onChange={handleChangeInput}
                  onFocus={() => setOpenLocationDropdown(true)}
                  icon="search"
                  value={venueValue}
                  isLoading={isPlacePredictionsLoading}
                />
              </Form.Item>
            </Dropdown>
          </div>
        </div>
      )}

      {/*{isCustomAddress && <div>custom address</div>}*/}
      {selectedAddress && (
        <div style={{ marginTop: 24, marginBottom: 24 }}>
          <div className={s.infoLocation}>
            <div>
              <Typography type="small" className={cn(s.label, s.required)}>
                Street
              </Typography>
              <div>
                <Form.Item name={['address', 'street']}>
                  <Input
                    name="street"
                    className={isRequiredStreet && s.inputError}
                    style={{ backgroundColor: 'white', width: '100%' }}
                  />
                </Form.Item>
                <Label className={`${s.errorMessage} ${isRequiredStreet ? s.visible : ''}`}>
                  Street is required
                </Label>
              </div>
            </div>
            <div className={s.block}>
              <div style={{ width: '100%' }}>
                <Typography type="small" className={cn(s.label, s.required)}>
                  {t('common_city')}
                </Typography>

                <div>
                  <Form.Item name={['address', 'town']}>
                    <Input
                      name="town"
                      className={isRequiredTown && s.inputError}
                      style={{ backgroundColor: 'white', width: '100%' }}
                    />
                  </Form.Item>
                  <Label className={`${s.errorMessage} ${isRequiredTown ? s.visible : ''}`}>
                    Town is required
                  </Label>
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <Typography type="small" className={cn(s.label, s.required)}>
                  {t('common_postcode')}
                </Typography>
                <div>
                  <Form.Item name={['address', 'postcode']}>
                    <Input
                      name="postcode"
                      className={isRequiredPostcode && s.inputError}
                      style={{ backgroundColor: 'white', width: '100%' }}
                    />
                  </Form.Item>
                  <Label className={`${s.errorMessage} ${isRequiredPostcode ? s.visible : ''}`}>
                    Postcode is required
                  </Label>
                </div>
              </div>
            </div>

            {/*<div>*/}
            {/*  <Typography type="small" className={cn(s.label)}>*/}
            {/*    {t('common_country')}*/}
            {/*  </Typography>*/}
            {/*  <Form.Item name={['location', 'country']} fullWidth initialValue="Malta">*/}
            {/*    <Input name="street" fullWidth />*/}
            {/*  </Form.Item>*/}
            {/*</div>*/}
          </div>
          {!isCustomAddress && (
            <div className={s.infoLocation}>
              <div className={s.mapWrapper}>
                <Button className={s.changeButton} onClick={toggleMapStyle}>
                  <SyncOutlined />
                </Button>
                <Map
                  ref={mapRef}
                  initialViewState={{
                    longitude: lon ? lon : 14.5146, // Center the map around Malta's coordinates
                    latitude: lat ? lat : 35.8989,
                    zoom: lon ? 18 : 11,
                    bearing: 0,
                    pitch: 0,
                  }}
                  mapStyle={showMapStyle}
                  mapboxAccessToken={MAPBOX_TOKEN}>
                  <GeolocateControl position="bottom-right" />
                  <FullscreenControl position="bottom-right" />
                  <NavigationControl position="bottom-right" />
                  <ScaleControl />

                  <Marker
                    longitude={markerPosition?.longitude}
                    latitude={markerPosition?.latitude}
                    draggable
                    onDragEnd={handleMarkerDragEnd}
                    // longitude={14.5146} // Center the map around Malta's coordinates
                    // latitude={35.8989}

                    anchor="bottom"></Marker>
                </Map>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PropertyLocationVenue;
