import { RootState } from 'store';

import { GetPropertyType, PropertyType } from 'types/property/propertyTypes';

export const propertySliceSelector = (state: RootState) => state.propertySlice;

export const createActivePropertySelector = (state: RootState): PropertyType | undefined =>
  propertySliceSelector(state)?.activeProperty;

export const propertyListSelector = (state: RootState): GetPropertyType[] | undefined =>
  propertySliceSelector(state)?.propertyList;
