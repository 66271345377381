import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GetPropertyType, PropertyType } from 'types/property/propertyTypes';

export type PropertySliceType = {
  activeProperty?: PropertyType;
  propertyList?: GetPropertyType[];
};

export const initialState: PropertySliceType = {};

export const propertySlice = createSlice({
  name: 'propertySlice',
  initialState,
  reducers: {
    setActiveProperty: (state, action: PayloadAction<PropertyType | undefined>) => {
      state.activeProperty = action.payload;
    },
    setPropertyList: (state, action: PayloadAction<GetPropertyType[] | undefined>) => {
      state.propertyList = action.payload;
    },
    removePropertyById: (state, action) => {
      const idToRemove = action.payload; // Get the id passed in the action
      state.propertyList = state.propertyList?.filter((property) => property.id !== idToRemove);
    },
  },
});

export const { setActiveProperty, setPropertyList, removePropertyById } = propertySlice.actions;
