import { SERVICES_TAGS } from 'constants/servicesTags';
import initialApiService from 'services/initialApiService';

import {
  PropertyFullInfoTypeList,
  PropertyRequestType,
  PropertyType,
  getPropertiesReqType,
  getPropertiesResType,
  uploadImageType,
} from 'types/property/propertyTypes';

const propertyApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getPropertyInfo: builder.query<PropertyFullInfoTypeList, string>({
      query: (type) => ({
        url: `/propertytypes/list/${type}`,
        method: 'GET',
      }),
      providesTags: [SERVICES_TAGS.PROPERTY_FULL_INFO],
    }),
    createProperty: builder.mutation<PropertyType, PropertyRequestType>({
      query: (body) => ({
        url: 'properties/create',
        method: 'POST',
        body,
      }),
    }),
    updateProperty: builder.mutation<PropertyType, PropertyRequestType>({
      query: (data) => ({
        url: `properties/${data.id}/edit`,
        method: 'PATCH',
        body: data,
      }),
    }),
    uploadPropertyImage: builder.mutation<PropertyType, uploadImageType>({
      query: (body) => ({
        url: 'properties/image',
        method: 'POST',
        body,
      }),
    }),
    deletePropertyImage: builder.mutation<PropertyType, string>({
      query: (id) => ({
        url: `properties/deleteimage/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteProperty: builder.mutation<PropertyType, string>({
      query: (id) => ({
        url: `properties/delete/${id}`,
        method: 'DELETE',
      }),
    }),
    getProperties: builder.mutation<getPropertiesResType, getPropertiesReqType>({
      query: (body) => ({
        url: 'properties/list',
        method: 'POST',
        body,
      }),
    }),
    getPropertyDetail: builder.query<PropertyType, string>({
      query: (id) => ({
        url: `/properties/detail/${id}`,
        method: 'GET',
      }),
      providesTags: [SERVICES_TAGS.PROPERTY_DETAIL],
    }),
  }),
});

export const {
  useLazyGetPropertyInfoQuery,
  useUploadPropertyImageMutation,
  useDeletePropertyImageMutation,
  useDeletePropertyMutation,
  useCreatePropertyMutation,
  useUpdatePropertyMutation,
  useGetPropertiesMutation,
  useLazyGetPropertyDetailQuery,
} = propertyApiService;
