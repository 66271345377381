import { FC, useCallback, useEffect } from 'react';

import MapComponent from 'ui/map/Map';

import { propertyListSelector } from 'selectors/propertySlice.selector';
import { useGetPropertiesMutation } from 'services/properties/propertyApiService';
import { setPropertyList } from 'slices/propertySlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import { GetPropertyType } from 'types/property/propertyTypes';

import s from './Dashboard.module.scss';

const Dashboard: FC = () => {
  const dispatch = useAppDispatch();

  const [getProperties] = useGetPropertiesMutation();
  const propertyList = useAppSelector(propertyListSelector);

  useEffect(() => {
    const criteria = {
      pageNumber: 1,
      pageSize: 100,
    };
    getProperties(criteria);
  }, [getProperties]);

  const handleGetPropertyList = useCallback(
    async (criteria) => {
      const res = await getProperties(criteria);
      if ('data' in res) {
        dispatch(setPropertyList(res.data.properties as GetPropertyType[]));
      }
    },
    [dispatch, getProperties],
  );

  useEffect(() => {
    const criteria = {
      pageNumber: 1,
      pageSize: 100,
    };

    handleGetPropertyList(criteria);
  }, [handleGetPropertyList]);

  return (
    <div className={s.wrapper}>
      <div>
        <MapComponent properties={propertyList!} />
      </div>
    </div>
  );
};

export default Dashboard;
