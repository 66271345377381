import React, { FC, useState } from 'react';

import { SyncOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import 'mapbox-gl/dist/mapbox-gl.css';
import Map, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
  ScaleControl,
} from 'react-map-gl';
import { Icon } from 'ui';
import PropertyCard from 'ui/propertyCard';

import { GetPropertyType } from 'types/property/propertyTypes';

// Import Mapbox CSS
import s from './Map.module.scss';

const MAPBOX_TOKEN = process.env.REACT_APP_MAP_BOX_TOKEN;

interface MapComponentProps {
  properties: GetPropertyType[];
}

const MapComponent: FC<MapComponentProps> = ({ properties }) => {
  const [showMapStyle, setShowMapStyle] = useState('mapbox://styles/mapbox/streets-v12');
  const [popupInfo, setPopupInfo] = useState<GetPropertyType | null>(null);
  const [showList, setShowList] = useState<boolean>(false);

  const toggleMapStyle = () => {
    // Toggle between satellite and street view styles
    const newMapStyle =
      showMapStyle === 'mapbox://styles/mapbox/streets-v12'
        ? 'mapbox://styles/mapbox/satellite-streets-v12'
        : 'mapbox://styles/mapbox/streets-v12';

    setShowMapStyle(newMapStyle);
  };

  const formatPrice = (value: number): string => {
    if (value >= 1_000_000) {
      return `€${(value / 1_000_000).toFixed(1)} M`;
    } else if (value >= 1_000) {
      return `€${(value / 1_000).toFixed(1)} K`;
    } else {
      return `€${value}`;
    }
  };

  const pins = properties?.map((item, index) => {
    return (
      <Marker
        key={`marker-${index}`}
        longitude={item.coordinates.longitude}
        latitude={item.coordinates.latitude}
        anchor="bottom"
        onClick={(e) => {
          e.originalEvent.stopPropagation();
          setPopupInfo(item);
        }}>
        <div className={s.marker}>
          <div className={s.pricePanel}>{formatPrice(item.price)}</div>
          <Icon className={s.icon} size={32} name="buy" />
        </div>
      </Marker>
    );
  });

  return (
    <div className={s.mapWrapper}>
      <Button className={s.changeButton} onClick={toggleMapStyle}>
        <SyncOutlined />
      </Button>
      {showList && (
        <div className={s.propertyList}>
          {properties?.map((item, index) => {
            return <PropertyCard editable={false} key={index} property={item} />;
          })}
        </div>
      )}
      <Button className={s.showList} onClick={() => setShowList(!showList)}>
        <UnorderedListOutlined /> Show Properties in List
      </Button>
      <Map
        initialViewState={{
          longitude: 14.5146, // Center the map around Malta's coordinates
          latitude: 35.8989,
          zoom: 11,
          bearing: 0,
          pitch: 0,
        }}
        mapStyle={showMapStyle}
        mapboxAccessToken={MAPBOX_TOKEN}>
        <GeolocateControl position="bottom-right" />
        <FullscreenControl position="bottom-right" />
        <NavigationControl position="bottom-right" />
        <ScaleControl />

        {pins}

        {popupInfo && (
          <Popup
            className="z-50"
            anchor="top"
            offset={[-32, 16]}
            longitude={Number(popupInfo.coordinates.longitude)}
            latitude={Number(popupInfo.coordinates.latitude)}
            onClose={() => setPopupInfo(null)}>
            <PropertyCard editable={false} property={popupInfo} />
          </Popup>
        )}
      </Map>
    </div>
  );
};

export default MapComponent;
